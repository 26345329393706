<template>
  <div class="offers pt-10">
    <div class="offers__content topRadius pt-8">
      <div class="d-flex align-start">
        <div class="skeletonUpselling" v-if="!loaded">
          <transition name="fade">
            <Placeholder type="commodityOffer" />
          </transition>
        </div>

        <transition name="fade">
          <div v-if="loaded">
            <div class="customContainerOffer">
              <div class="offers__offer">
                <div class="labelOffer">
                  <div class="LabelS">{{ product.priceQuoteText }}</div>
                  <div class="LabelM">
                    {{ catalogList.endDateValidityFinalText }}
                  </div>
                </div>
                <div class="offerHeader">
                  <div class="SubheadingL">
                    {{ product.productTitle }}
                  </div>
                  <img
                    contain
                    :src="
                      commodityType === 'ele'
                        ? require('../../../../assets/icons/ic-light-blu.svg')
                        : require('../../../../assets/icons/ic-fire-blu.svg')
                    "
                    width="32"
                    height="32"
                  />
                </div>
                <div class="Price">
                  <div class="section">
                    <div class="LabelM">{{ product.priceDetailTitle }}</div>
                    <div class="BodyL">
                      {{ product.priceDetail }}
                    </div>
                  </div>
                  <div class="section">
                    <div class="LabelM">
                      {{ product.commercialServicesTitle }}
                    </div>
                    <div class="BodyL">
                      {{ product.commercialServices }}
                    </div>
                  </div>
                  <div class="section">
                    <div class="LabelM">{{ product.discountTitle }}</div>
                    <div class="BodyL">
                      {{ product.discountText }}
                    </div>
                  </div>
                  <v-divider v-if="checkAnnualConsumption()" class="divider" />
                  <div
                    v-if="checkAnnualConsumption()"
                    class="monthlyEstimateSection"
                  >
                    <div class="text">
                      <div class="LabelM">{{ i4c.monthlyExpensesTitle }}</div>
                      <div
                        class="ButtonM-Underline"
                        @click="openHowCalculatedDetail()"
                      >
                        {{ product.quantitativeModalTitle }}
                      </div>
                    </div>
                    <div class="number">
                      <div class="SubheadingXL">
                        {{ i4c.monthlyFinalCost }}
                      </div>
                      <div v-if="i4c.monthlyBaseCost" class="BodyM-Strong">
                        {{ i4c.monthlyBaseCost }}
                      </div>
                    </div>
                  </div>
                  <v-divider v-if="checkAnnualConsumption()" class="divider" />
                  <div class="infoPriceContracts">
                    <div class="ButtonL mr-8" @click="openDoc()">
                      {{ catalogList.contractTermsText }}
                    </div>
                    <div class="ButtonL" @click="openPriceDetail()">
                      {{ product.priceDetailText }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ctaSection mb-8">
              <MYSButton
                :disabled="!loaded"
                width="300"
                buttonText="Inizia la sottoscrizione"
                buttonClass="primaryButtonL"
                @buttonClick="next()"
              />
              <div @click="goToSpeakWith" class="ButtonL callme">
                Parla con un consulente
              </div>
            </div>
            <div v-if="benefits.benefitList" class="detailOffer">
              <div class="BodyL-Strong mb-4">
                {{ benefits.benefitTitle }}
              </div>
              <div v-for="(benefit, i) in benefits.benefitList" :key="i">
                <div
                  v-if="
                    (commodityType === 'ele' && benefit.electricityFlag) ||
                    (commodityType === 'gas' && benefit.gasFlag)
                  "
                  class="benefit"
                >
                  <img
                    class="icon"
                    :src="loadIconClass(benefit.benefitIconClass)"
                    width="24"
                  />
                  <div class="BodyL" v-html="benefit.title"></div>
                  <img
                    v-if="benefit.deepDiveModalFlag"
                    src="../../../../assets/upselling/icons/icon-info.svg"
                    width="24"
                    class="imgInfo"
                    @click="
                      openCustomDialog(
                        benefit.imageModal,
                        benefit.modalText,
                        benefit.modalTitle
                      )
                    "
                  />
                </div>
              </div>
            </div>
            <MYSButton
              :buttonText="consumptions.consumptionCTA"
              buttonClass="primaryInvertedButtonBorded mt-9"
              @buttonClick="montlhyEstimateModalOpen = true"
            />
          </div>
        </transition>
      </div>
    </div>
    <CustomDialog
      :customDialogOpen.sync="customDialogOpen"
      :body="bodyModal"
      :title="titleModal"
      :image="imageModal"
      :showImage="showImage"
    />
    <PdfViewer
      :showDialog="dialogTermCond"
      :url="contractDataUrl"
      @closing-pdfViewer="dialogTermCond = false"
      :attachTo="'body'"
      height="600px"
    />
    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      cta1="Riprova ora"
      cta2="Torna alla homepage"
      @retry="loadOfferPreview"
      @returnToHome="returnToHome"
    />
    <MonthlyEstimateModal
      :montlhyEstimateModalOpen.sync="montlhyEstimateModalOpen"
      :body="consumptions?.consumptionModalText"
      :title="consumptions?.consumptionModalTitle"
      :label="consumptions?.consumptionTitle"
      :example="consumptions?.consumptionExample"
      :errorCopy="consumptions?.consumptionErrorCopy"
      :ctaCopy="consumptions?.consumptionCalc"
      :min="Number(consumptions?.consumptionMin)"
      :max="Number(consumptions?.consumptionMax)"
      :offerType="commodityType"
      @checkAnnualConsumption="annualConsumptionInsert"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CustomDialog from '@/components/upselling/gas&light/CustomDialog'
import ServiceUnavailableDialog from '@/components/global/ServiceUnavailableDialog.vue'
import Placeholder from '@components/global/Placeholder.vue'
import MYSButton from '../../../../components/global/MYSButton.vue'
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import MonthlyEstimateModal from '../components/MonthlyEstimateModal.vue'
import { min } from 'lodash'
export default {
  name: 'newCommodityOffer',
  components: {
    CustomDialog,
    ServiceUnavailableDialog,
    MYSButton,
    Placeholder,
    PdfViewer,
    MonthlyEstimateModal
  },
  data() {
    return {
      offerList: null,
      emptyState: false,
      loaded: false,
      customDialogOpen: false,
      imageModal: null,
      bodyModal: null,
      titleModal: null,
      showImage: true,
      documentLoaded: false,
      contractDataUrl: '',
      dialogTermCond: false,
      serviceUnavailableDialogOpen: false,
      titleServiceError: '',
      bodyServiceError: '',
      imageServiceError: '',
      montlhyEstimateModalOpen: false,
      titleModalMonthlyEstimate: 'Stima media mensile',
      bodyModalMonthlyEstimate:
        "Il consumo annuo indica il consumo in Smc, degli ultimi 12 mesi di fornitura, fino alla data di ricezione. Normalmente questa informazione è inserita nella seconda pagina della tua bolletta. La famiglia italiana consuma in media circa 1.400 Smc all'anno.",
      labelModalMonthlyEstimate: 'consumo annuo (smc/anno)',
      annualConsumption: null
    }
  },

  mounted() {
    this.loadOfferPreview()
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'commodityType',
      'client',
      'getInfoUpselling',
      'supplyUpselling',
      'address',
      'supplyDetail',
      'getFlagAddedAddress'
    ]),
    ...mapGetters('account', ['accountClients', 'accountProperties']),
    ...mapGetters('session', ['username']),

    benefits() {
      return this.infoUpselling.benefits
    },
    product() {
      return this.infoUpselling.product
    },
    catalogList() {
      return this.infoUpselling.catalogList[0]
    },
    infoUpselling() {
      return this.getInfoUpselling
    },
    consumptions() {
      return this.infoUpselling.consumptions
    },
    i4c() {
      return this.infoUpselling.i4c || {}
    }
  },
  methods: {
    ...mapActions('upsellingComm', ['getListino', 'retrieveInfoUpsellingV2']),
    ...mapMutations('upsellingComm', [
      'setOffer',
      'setSupplyDetailRoot',
      'updateAddress',
      'setClient',
      'setFlagAddedAddress'
    ]),
    ...mapActions('offers', ['getUpselling', 'getDocumentCommodity']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('egon', ['getEgonComune', 'getComune']),
    ...mapActions('account', ['getDetailForClient']),

    async loadOfferPreview() {
      this.loaded = false
      try {
        await this.requestType(this.supplyUpselling)
        let params = {}
        if (!this.annualConsumption) {
          params = {
            contractType: this.commodityType === 'ele' ? 'ELE' : 'GAS',
            quote: false
          }
        } else {
          params = {
            contractType: this.commodityType === 'ele' ? 'ELE' : 'GAS',
            quote: true,
            gasSupplyCityCode: this.address.codComune,
            annualEleConsumption:
              this.commodityType === 'ele' ? this.annualConsumption : null,
            annualGasConsumption:
              this.commodityType === 'gas' ? this.annualConsumption : null
          }
        }
        await this.retrieveInfoUpsellingV2(params)
        this.contractDataUrl = await this.getDocumentCommodity(
          this.catalogList.docName
        )
        this.loaded = true
        this.trackTapEvent({
          name: 'subscription_offer_preview_viewed',
          params: {
            services_to_subscribe: this.commodityType,
            funnel_segment: this.client?.clientType?.toLowerCase()
          }
        })
      } catch (error) {
        console.error(error)
        this.serviceUnavailableDialogOpen = true
        this.titleServiceError = 'Servizio temporaneamente non disponibile'
        this.bodyServiceError =
          'Il servizio è temporaneamente non disponibile. Riprova ora oppure in un secondo momento.'
        this.imageServiceError = 'KO-500'
      }
    },
    openCustomDialog(imageModal, bodyModal, titleModal) {
      this.showImage = false
      this.imageModal = imageModal
      this.bodyModal = bodyModal
      this.titleModal = titleModal
      this.customDialogOpen = true
    },
    openPriceDetail() {
      this.customDialogOpen = true
      this.imageModal = ''
      this.showImage = false
      this.bodyModal = this.product.priceDetailModal
      this.titleModal = this.product.priceDetailTitleModal
    },
    openHowCalculatedDetail() {
      this.customDialogOpen = true
      this.imageModal = ''
      this.showImage = false
      this.bodyModal = this.product.quantitativeModal
      this.titleModal = this.product.quantitativeModalTitle
    },
    openDoc() {
      this.dialogTermCond = true
      this.trackTapEvent({
        name: `upsellingcom_getpdfcontract${'24'}_tap`,
        params: {
          supply_type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.client.clientOwnerCode || ''
        }
      })
    },
    checkAnnualConsumption() {
      return Object.keys(this.i4c).length !== 0
    },
    annualConsumptionInsert(value) {
      this.annualConsumption = value
      this.loadOfferPreview()
    },
    returnToHome() {
      this.$router.push({ name: 'home' })
    },
    next() {
      this.setOffer(this.offerList)
      this.$emit('nextStep')
      this.trackTapEvent({
        name: `upsellingcom_offergas_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    },
    loadIconClass(iconClass) {
      try {
        return require(`@/assets/upselling/icons/${iconClass}.svg`)
      } catch (error) {
        return require(`@/assets/upselling/icons/icon-fallback.svg`)
      }
    },
    checkDataBenefit(benefit) {
      if (
        benefit.title !== null &&
        benefit.modalTitle !== null &&
        benefit.modalText !== null &&
        benefit.benefitIconClass !== null
      ) {
        return true
      } else {
        return false
      }
    },

    getProdutcs(value) {
      return value
    },
    goToSpeakWith() {
      let name = this.commodityType === 'ele' ? 'eleOffering' : 'gasOffering'
      // this.$router.push({ name: name, params: { from: 'speakWith' } })
    },
    async requestType(supply) {
      let comuneEgon = await this.getEgonComune(supply.city)
      let codComune = await this.getComune(supply.city)

      let resp = Object.values(this.accountClients)
      resp = resp.filter(
        (client) => supply.clientOwnerCode === client.clientOwnerCode
      )[0]

      await this.getDetailForClient(resp).then((clientDetails) => {
        const clientWithDetails = {
          ...resp,
          ...clientDetails,
          username: this.username
        }
        this.setClient(clientWithDetails)
      })
      if (!this.getFlagAddedAddress) {
        this.updateAddress({
          clientOwnerCode: supply.clientOwnerCode,
          address: supply.addressComplete,
          egonLocality: supply.egonLocality,
          egonRegion: supply.egonRegion,
          egonStreet: supply.egonStreet,
          egonStreetNumber: supply.egonStreetNumber,
          streetNumber: supply.streetNumber,
          egonStreetNumberLetter: supply.egonStreetNumberLetter,
          comune: supply.city,
          comuneEgon: comuneEgon.comuneEgon,
          codComune: codComune[0].codComune,
          provincia: supply.county,
          zip: supply.zipCode
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ctaSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 652px;
}
.Price {
  .section {
    margin-bottom: 24px;
  }
  .ButtonL {
    color: $srg-blue;
    cursor: pointer;
  }
  .infoPriceContracts {
    margin-top: 24px;
    display: flex;
  }
}
.divider {
  border-color: rgba(0, 0, 0, 0);
  background-color: $neutri-neutral-20;
}
.customContainerOffer {
  height: 100%;
}
.offerHeader {
  width: 604px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.detailOffer {
  width: 604px;
  height: auto;
  gap: 16px;
}
.benefit {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  .imgInfo {
    cursor: pointer;
  }
  .icon {
    filter: brightness(0);
  }
}
.monthlyEstimateSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  .text {
    display: flex;
    flex-direction: column;
    .ButtonM-Underline {
      margin-top: 8px;
      color: $srg-blue;
      cursor: pointer;
    }
  }
  .number {
    display: flex;
    flex-direction: column;
    .BodyM-Strong {
      color: $neutri-neutral-50;
      text-align: end;
      text-decoration: line-through;
    }
  }
}
.offers {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;

  &__content {
    margin-top: -68px !important;
    background: white;
    padding-bottom: 50px;
  }
  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 48px;
    cursor: pointer;
    img {
      margin-right: 16px;
    }
  }
  &__offer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 652px;
    height: 100%;
    background-color: $neutri-neutral-10;
    padding: 24px;
    border-radius: 16px;
    gap: 12px;
    margin-bottom: 32px;
    .labelOffer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;
      .LabelS {
        background-color: $neutri-neutral-30;
        padding: 7px 8px 7px 8px;
        border-radius: 8px;
        text-transform: uppercase;
      }
    }
  }
  .callme {
    margin-left: 34px;
    text-align: center;
    color: $srg-blue;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.skeletonUpselling {
  display: flex;
  flex-direction: column;
}
.skeletonUpselling {
  display: flex;
  flex-direction: column;
}
</style>
