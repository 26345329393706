<template>
  <div class="offers mb-12 pt-10">
    <div class="offers__content std-side-padding topRadius pt-8">
      <ProgressBar :value="15" />
      <div
        class="offers__back mb-8"
        @click="
          $router.push({ name: type === 'ele' ? 'eleOffering' : 'gasOffering' })
        "
      >
        <img src="../../../assets/ic-chevron-sx-blue.svg" width="24" />
        <div class="HeadingM" style="color: #12256a">
          Offerta {{ type === 'ele' ? 'Luce' : 'Gas' }}
        </div>
      </div>
      <div class="d-flex align-start">
        <div class="skeletonUpselling" v-if="!loaded">
          <transition name="fade">
            <Placeholder type="upsellingAnteprima" />
          </transition>
          <transition name="fade">
            <Placeholder type="upsellingAnteprimaNoBg" />
          </transition>
        </div>

        <transition name="fade">
          <div v-if="loaded">
            <div class="customContainer">
              <div class="offers__offer">
                <div class="offerHeader">
                  <div class="SubheadingL">
                    {{ product.productTitle }}
                  </div>
                  <img
                    contain
                    :src="
                      type === 'ele'
                        ? require('../../../assets/icons/ic-light-blu.svg')
                        : require('../../../assets/icons/ic-fire-blu.svg')
                    "
                    width="32"
                    height="32"
                  />
                </div>
                <div class="Price">
                  <div class="BodyM">
                    {{ product.priceDetail }}
                  </div>
                  <div class="ButtonM detail" @click="openPriceDetail()">
                    {{ product.priceDetailText }}
                  </div>
                </div>
                <v-divider v-if="benefits.benefitList" class="divider" />
                <div v-if="benefits.benefitList" class="detailOffer">
                  <div class="BodyL-Strong mb-4">
                    {{ benefits.benefitTitle }}
                  </div>
                  <div v-for="(benefit, i) in benefits.benefitList" :key="i">
                    <div
                      v-if="
                        (type === 'ele' && benefit.electricityFlag) ||
                        (type === 'gas' && benefit.gasFlag)
                      "
                      class="benefit"
                    >
                      <img
                        :src="loadIconClass(benefit.benefitIconClass)"
                        width="24"
                      />
                      <div class="BodyL" v-html="benefit.title"></div>
                      <img
                        v-if="benefit.deepDiveModalFlag"
                        src="../../../assets/upselling/icons/icon-info.svg"
                        width="24"
                        class="imgInfo"
                        @click="
                          openCustomDialog(
                            benefit.imageModal,
                            benefit.modalText,
                            benefit.modalTitle
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="LabelM">
                {{ catalogList.endDateValidityFinalText }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="cta">
        <MYSButton
          :disabled="!loaded"
          buttonText="Scopri l’offerta"
          buttonClass="primaryButtonL"
          @buttonClick="next()"
        />
        <div @click="goToSpeakWith" class="ButtonL callme">
          Parla con un consulente
        </div>
      </div>
    </div>
    <CustomDialog
      :customDialogOpen.sync="customDialogOpen"
      :body="bodyModal"
      :title="titleModal"
      :image="imageModal"
      :showImage="showImage"
    />

    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      cta1="Riprova ora"
      cta2="Torna alla homepage"
      @retry="loadOfferPreview"
      @returnToHome="returnToHome"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ProgressBar from '@/components/upselling/gas&light/ProgressBar.vue'
import CustomDialog from '@/components/upselling/gas&light/CustomDialog'
import ServiceUnavailableDialog from '@/components/global/ServiceUnavailableDialog.vue'
import RegistrationChoise from '../../selfRegistration/RegistrationChoise.vue'
import Placeholder from '@components/global/Placeholder.vue'
import supply from '../../../store/modules/supply'
import MYSButton from '../../../components/global/MYSButton.vue'

export default {
  name: 'commodityOffers',
  props: ['type'],
  components: {
    ProgressBar,
    CustomDialog,
    ServiceUnavailableDialog,
    MYSButton,
    Placeholder
  },
  data() {
    return {
      offerList: null,
      emptyState: false,
      loaded: false,
      customDialogOpen: false,
      imageModal: null,
      bodyModal: null,
      titleModal: null,
      showImage: true,
      serviceUnavailableDialogOpen: false,
      titleServiceError: '',
      bodyServiceError: '',
      imageServiceError: ''
    }
  },

  mounted() {
    this.loadOfferPreview()
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'commodityType',
      'client',
      'getInfoUpselling',
      'supplyUpselling',
      'address',
      'supplyDetail',
      'getFlagAddedAddress'
    ]),
    ...mapGetters('account', ['accountClients', 'accountProperties']),
    ...mapGetters('session', ['username']),

    benefits() {
      return this.infoUpselling.benefits
    },
    product() {
      return this.infoUpselling.product
    },
    catalogList() {
      return this.infoUpselling.catalogList[0]
    },
    infoUpselling() {
      return this.getInfoUpselling
    }
  },
  methods: {
    ...mapActions('upsellingComm', ['getListino', 'retrieveInfoUpselling']),
    ...mapMutations('upsellingComm', [
      'setOffer',
      'setSupplyDetailRoot',
      'updateAddress',
      'setClient',
      'setFlagAddedAddress'
    ]),
    ...mapActions('offers', ['getUpselling']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('egon', ['getEgonComune', 'getComune']),
    ...mapActions('account', ['getDetailForClient']),

    async loadOfferPreview() {
      try {
        await this.requestType(this.supplyUpselling)
        const params = {
          contractType: this.commodityType === 'ele' ? 'ELE' : 'GAS',
          quote: false,
          resident: this.supplyDetail.isResident
        }

        await this.retrieveInfoUpselling(params)
        this.loaded = true
        this.trackTapEvent({
          name: 'subscription_offer_preview_viewed',
          params: {
            services_to_subscribe: this.commodityType,
            funnel_segment: this.client?.clientType?.toLowerCase()
          }
        })
      } catch (error) {
        this.serviceUnavailableDialogOpen = true
        this.titleServiceError = 'Servizio temporaneamente non disponibile'
        this.bodyServiceError =
          'Il servizio è temporaneamente non disponibile. Riprova ora oppure in un secondo momento.'
        this.imageServiceError = 'KO-500'
      }
    },
    openCustomDialog(imageModal, bodyModal, titleModal) {
      this.showImage = false
      this.imageModal = imageModal
      this.bodyModal = bodyModal
      this.titleModal = titleModal
      this.customDialogOpen = true
    },
    openPriceDetail() {
      this.customDialogOpen = true
      this.imageModal = ''
      this.showImage = false
      this.bodyModal = this.product.priceDetailModal
      this.titleModal = this.product.priceDetailTitleModal
    },
    returnToHome() {
      this.$router.push({ name: 'home' })
    },
    next() {
      this.setOffer(this.offerList)
      let name = this.type === 'ele' ? 'eleOffering' : 'gasOffering'
      this.$router.push({ name: name, params: { from: 'offer' } })
      // this.trackTapEvent({
      //   name: `upsellingcom_offer${mounth}_tap`,
      //   params: {
      //     type: this.$store.getters['upsellingComm/commodityType'],
      //     client_selected: this.$store.getters['upsellingComm/address']
      //       ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
      //         ''
      //       : 'not_selected'
      //   }
      // })
    },
    loadIconClass(iconClass) {
      try {
        return require(`@/assets/upselling/icons/${iconClass}.svg`)
      } catch (error) {
        return require(`@/assets/upselling/icons/icon-fallback.svg`)
      }
    },
    checkDataBenefit(benefit) {
      if (
        benefit.title !== null &&
        benefit.modalTitle !== null &&
        benefit.modalText !== null &&
        benefit.benefitIconClass !== null
      ) {
        return true
      } else {
        return false
      }
    },

    getProdutcs(value) {
      return value
    },
    goToSpeakWith() {
      let name = this.type === 'ele' ? 'eleOffering' : 'gasOffering'
      this.$router.push({ name: name, params: { from: 'speakWith' } })
    },
    async requestType(supply) {
      let comuneEgon = await this.getEgonComune(supply.city)
      let codComune = await this.getComune(supply.city)

      let resp = Object.values(this.accountClients)
      resp = resp.filter(
        (client) => supply.clientOwnerCode === client.clientOwnerCode
      )[0]

      await this.getDetailForClient(resp).then((clientDetails) => {
        const clientWithDetails = {
          ...resp,
          ...clientDetails,
          username: this.username
        }
        this.setClient(clientWithDetails)
      })
      if (!this.getFlagAddedAddress) {
        this.updateAddress({
          clientOwnerCode: supply.clientOwnerCode,
          address: supply.addressComplete,
          egonLocality: supply.egonLocality,
          egonRegion: supply.egonRegion,
          egonStreet: supply.egonStreet,
          egonStreetNumber: supply.egonStreetNumber,
          streetNumber: supply.streetNumber,
          egonStreetNumberLetter: supply.egonStreetNumberLetter,
          comune: supply.city,
          comuneEgon: comuneEgon.comuneEgon,
          codComune: codComune[0].codComune,
          provincia: supply.county,
          zip: supply.zipCode
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cta {
  margin-top: 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 497px;
}
.Price {
  height: 59px;

  .detail {
    padding: 8px 12px 8px 0px;
    color: $srg-blue;
    cursor: pointer;
  }
}
.divider {
  border-color: rgba(0, 0, 0, 0);
  background-color: $neutri-neutral-20;
}
.customContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  flex-grow: 1;
}
.offerHeader {
  width: 604px;
  display: flex;
  justify-content: space-between;
}
.detailOffer {
  width: 604px;
  height: auto;
  gap: 16px;
}
.benefit {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  .imgInfo {
    cursor: pointer;
  }
}
.offers {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;

  &__content {
    margin-top: -30px !important;
    background: white;
    padding-bottom: 100px;
  }
  &__back {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 48px;
    cursor: pointer;
    img {
      margin-right: 16px;
    }
  }
  &__offer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 652px;
    height: 100%;
    background-color: $neutri-neutral-10;
    padding: 24px;
    border-radius: 16px;
    gap: 12px;
    margin-bottom: 32px;
  }
  .callme {
    text-align: center;
    color: $srg-blue;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.conditions {
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      font-weight: 300;
      margin-bottom: 20px;
      position: relative;
      margin-left: 10px;
      font-size: 16px;
      color: black;
      line-height: 1.25;
      .li-title {
        font-weight: 500;
      }
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/icons/ic-check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -30px;
        top: -8px;
      }
      &:last-child {
        margin-bottom: 48px;
      }
    }
  }
}
.v-progress-linear {
  position: absolute;
  top: 15px;
  left: 0;
}
.stepFlow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  .step {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    text-align: right;
    position: relative;
    &.hidden {
      opacity: 0;
    }
    .label-step {
      color: #ccd0e1;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: normal;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -10px;
      &.complete {
        color: black;
      }
    }
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 21px;
      font-weight: 500;
      color: #ccd0e1;
      z-index: 1;
      background: white;
      border: 2px solid;
      border-color: #ccd0e1;
      transition: all 0.5s ease;
      span {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
      &::after {
        content: url('../../../assets/ic-check-white.svg');
        position: absolute;
        top: 2px;
        opacity: 0;
        transition: opacity 0.1s ease;
      }
      &.complete {
        animation: bounce 0.5s ease-in-out;
        animation-delay: 0.2s;
        background-color: #5eb83d;
        border-color: #5eb83d;
        color: #fff;
        position: relative;
        span {
          opacity: 0;
          transition: opacity 0.1s ease;
        }
        &::after {
          opacity: 1;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      background: #ccd0e1;
      top: 15px;
      left: 0;
      z-index: 0;
    }
  }
}
p {
  margin: 0 !important;
}
.skeletonUpselling {
  display: flex;
  flex-direction: column;
}
.skeletonUpselling {
  display: flex;
  flex-direction: column;
}
</style>
