<template>
  <div class="dataInputGas">
    <div v-if="typeRequest !== 'PR'">
      <div class="label">
        <div class="LabelM">PDR</div>
        <img
          class="ml-1 mb-1"
          src="../../../../assets/icons/info.svg"
          width="24"
          @click="openDetail('pdr')"
        />
      </div>
      <v-text-field
        v-model.lazy="pdr"
        @blur="v$.pdr.$touch()"
        type="text"
        name="pdr"
        :error-messages="pdrErrors"
        placeholder="Es: 12357654345654"
        persistent-placeholder
      />
    </div>
    <div v-if="typeRequest !== 'PR'">
      <div class="label">
        <div class="LabelM">CODICE REMI</div>
        <img
          class="ml-1 mb-1"
          src="../../../../assets/icons/info.svg"
          width="24"
          @click="openDetail('remi')"
        />
      </div>
      <v-text-field
        v-if="typeRequest !== 'PR'"
        v-model.lazy="remi"
        @blur="v$.remi.$touch()"
        type="text"
        name="remi"
        :error-messages="remiErrors"
        placeholder="Es: 34567654"
        persistent-placeholder
      />
    </div>
    <div class="label">
      <div class="LabelM">CONSUMO ANNUO (SMC/ANNO)</div>
      <img
        class="ml-1 mb-1"
        src="../../../../assets/icons/info.svg"
        width="24"
        @click="openDetail('consumption')"
      />
    </div>
    <v-text-field
      v-model.lazy="consumption"
      @blur="v$.consumption.$touch()"
      type="number"
      name="consumption"
      :error-messages="consumptionErrors"
      placeholder="Es: 100"
      persistent-placeholder
    />
    <MYSButton
      width="175"
      buttonText="Continua"
      buttonClass="primaryButton"
      :disabled="isInvalid"
      @buttonClick="next"
    />
    <CustomDialog
      :showImage="false"
      :customDialogOpen.sync="dialogDetail.show"
      :body="dialogDetail.desc"
      :title="dialogDetail.title"
      @close="dialogDetail.show = false"
    />
  </div>
</template>
<script>
import { maxLength, minLength, required, between } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import MYSButton from '@/components/global/MYSButton.vue'
import CustomDialog from '@/components/upselling/gas&light/CustomDialog.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'newSupplierDataInputGas',
  components: {
    MYSButton,
    CustomDialog
  },
  data() {
    return {
      consumption: null,
      remi: null,
      pdr: null,
      dialogDetail: {
        show: false,
        title: '',
        desc: ''
      },
      me: null
    }
  },
  mounted() {
    if (this.commodityType === 'gas') {
      this.consumption =
        this.getAnnualConsumption || this.supplyDetail.gas.consumption || null
    } else {
      this.consumption =
        this.getAnnualConsumption || this.supplyDetail.ele.consumption || null
    }
  },
  watch: {
    'v$.pdr.$error': function (val) {
      val
        ? this.setValidationErrors('PDR_INVALID')
        : this.deleteValidationError('PDR_INVALID')
    },
    'v$.consumption.$error': function (val) {
      val
        ? this.setValidationErrors('CONSUMPTION_INVALID')
        : this.deleteValidationError('CONSUMPTION_INVALID')
    },
    'v$.remi.$error': function (val) {
      val
        ? this.setValidationErrors('REMI_INVALID')
        : this.deleteValidationError('REMI_INVALID')
    }
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'flowType',
      'typeRequest',
      'getAnnualConsumption',
      'getInfoUpselling',
      'supplyDetail',
      'commodityType'
    ]),
    isInvalid() {
      if (this.typeRequest === 'PR') {
        return this.consumptionErrors.length > 0 || this.v$.consumption.$invalid
      } else {
        return (
          this.consumptionErrors.length > 0 ||
          this.remiErrors.length > 0 ||
          this.pdrErrors.length > 0 ||
          this.v$.consumption.$invalid ||
          this.v$.remi.$invalid ||
          this.v$.pdr.$invalid
        )
      }
    },
    consumptionErrors: function () {
      let errArray = []

      if (!this.v$.consumption.$dirty) return errArray
      this.v$.consumption.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      this.v$.consumption.between.$invalid === true &&
        errArray.push(this.getInfoUpselling.consumptions.consumptionErrorCopy)
      return errArray
    },
    remiErrors: function () {
      let errArray = []

      if (this.typeRequest === 'PR') return []

      if (!this.v$.remi.$dirty) return errArray
      this.v$.remi.maxLength.$invalid === true &&
        errArray.push('Il codice non soddisfa i requisiti')
      this.v$.remi.pattern.$invalid === true &&
        errArray.push('Il codice non soddisfa i requisiti')
      this.v$.remi.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')

      return errArray
    },
    pdrErrors: function () {
      let errArray = []

      if (this.typeRequest === 'PR') return []
      if (!this.v$.pdr.$dirty) return errArray

      this.v$.pdr.minLength.$invalid === true &&
        errArray.push('Il campo PDR non è valido')
      this.v$.pdr.maxLength.$invalid === true &&
        errArray.push('Il campo PDR non è valido')
      this.v$.pdr.pattern.$invalid === true &&
        errArray.push('Il campo PDR non è valido')
      this.v$.pdr.required.$invalid === true &&
        errArray.push('Il campo è obbligatorio')
      this.v$.pdr.checkPdr.$invalid === true &&
        errArray.push('Il campo PDR non è valido')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setSupplyDetail',
      'setValidationErrors',
      'deleteValidationError'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('upsellingComm', ['checkPDR']),
    next() {
      this.setSupplyDetail({
        consumption: parseInt(this.consumption),
        remi: this.remi,
        pdr: this.pdr
      })
      this.$emit('nextStep', 'upsellingcom_titolarity_open')
    },
    openDetail(type) {
      const text = {
        pdr: {
          title: 'PDR',
          desc: "Lo trovi in bolletta. È un codice composto da 14 caratteri: i primi 4 identificano l'impresa di distribuzione, i restanti 10 rappresentano un codice univoco stabilito dalla stessa impresa di distribuzione e riferito al PDR. Serve ad individuare il punto dove il distributore riconsegna il gas per la fornitura al cliente finale."
        },
        remi: {
          title: 'Codice REMI',
          desc: 'La cabina REMI del gas corrisponde al Punto di Consegna del gas (PdC), dove questo viene consegnato dalla società di trasporto alla società di distribuzione. Puoi trovare questa informazione sulla bolletta.'
        },
        consumption: {
          title: 'Consumi annui [SMC/Anno]',
          desc: "Inserisci qui il dato di consumo gas annuo letto sulla tua ultima bolletta. Normalmente questa informazione è inserita nella seconda pagina della tua bolletta. La famiglia media italiana consuma circa 1.400 metri cubi all'anno."
        }
      }
      this.dialogDetail = {
        show: true,
        title: text[type].title,
        desc: text[type].desc
      }
      this.trackTapEvent({
        name: `upsellingcom_info${type}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  },
  created() {
    this.consumption =
      this.$store.getters['upsellingComm/supplyDetail'].gas.consumption
    this.remi = this.$store.getters['upsellingComm/supplyDetail'].gas.remi
    this.pdr = this.$store.getters['upsellingComm/supplyDetail'].gas.pdr
    this.me = this
  },
  validations() {
    return {
      consumption: {
        required,
        between: between(
          Number(this.getInfoUpselling.consumptions.consumptionMin),
          Number(this.getInfoUpselling.consumptions.consumptionMax)
        )
      },
      remi: {
        required,
        maxLength: maxLength(20),
        pattern: (value) => {
          const patt = /^[a-zA-Z0-9]*$/
          return patt.test(value)
        }
      },
      pdr: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(14),
        checkPdr: (value, vm) => {
          if (value) return vm.checkPDR(value)
          return { $invalid: true }
        },
        pattern: (value) => {
          const patt = /^[0-9]*$/
          return patt.test(value)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-detail,
.dialog-offer {
  background: white;
  padding: 55px 32px 45px 32px;
  border-radius: 40px;
  position: relative;

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  .description {
    font-size: 16px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;

    &.preview {
      position: initial;
    }
  }
}

.dataInputGas {
  max-width: 652px;
  color: black;
  .label {
    display: flex;
    align-items: center;
    .LabelM {
      color: $neutri-neutral-50;
    }
    img {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.dataInputGas {
  .v-text-field {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 24px;
  }
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      margin-right: 2px;
      margin-top: 2px;
      &::before {
        content: url('../../../../assets/icons/Error.svg');
        margin-right: 2px;
      }
    }
    input {
      color: red !important;
    }
  }
}
</style>
