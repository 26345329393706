import { axiosACN } from '@/js/axiosInstances'
import Consts from '@/js/constants'
import axios from 'axios'
import MobileDetect from 'mobile-detect'
import { telecomCow } from './constants'

const getRequiredValidationError = (
  vuelidateInstance,
  fieldName,
  errorMessage = 'Il campo è obbligatorio'
) => {
  let errArray = []

  if (!vuelidateInstance[fieldName].$dirty) return errArray

  vuelidateInstance[fieldName].required === false && errArray.push(errorMessage)

  return errArray
}

const validateClientCode = (value) => {
  const cc = /^[a-zA-Z0-9]{2,}$/i
  return cc.test(value)
}
const validateCFPI = (value) => {
  const cf =
    /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
  const pi = /^[0-9]{11}$/

  return cf.test(value) || pi.test(value)
}
const validateCF = (value) => {
  const cf =
    /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i

  return cf.test(value)
}

const validateIban = (value) => {
  const iban =
    /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i
  return iban.test(value)
}

const checkIban = (elem) => {
  return new Promise((resolve, reject) => {
    let CODE_LENGTHS = {
      AT: 20,
      BE: 16,
      BG: 22,
      CH: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      EE: 20,
      ES: 24,
      FI: 18,
      FR: 27,
      GB: 22,
      GR: 27,
      HR: 21,
      HU: 28,
      IE: 22,
      IM: 22,
      IS: 26,
      IT: 27,
      JE: 22,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MT: 31,
      NL: 18,
      NO: 15,
      PL: 28,
      PT: 25,
      RO: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27
    }

    let sInsertedCountryCode = ''
    let sInsertedIban = elem.value
    if (elem.value.length > 1) {
      sInsertedIban = String(sInsertedIban)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '')
      elem.value = sInsertedIban
      sInsertedCountryCode = elem.value.substring(0, 2).toUpperCase()
      if (sInsertedCountryCode in CODE_LENGTHS) {
        sInsertedIban = sInsertedIban.substring(
          0,
          CODE_LENGTHS[sInsertedCountryCode]
        )
        elem.value = sInsertedIban
      }
    }

    let input = elem.value

    let iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
    elem.value = iban
    let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
    let digits = null
    // check syntax and length
    if (iban.length === 0 || !code || iban.length !== CODE_LENGTHS[code[1]]) {
      reject(
        new Error(
          "L'Iban inserito non è corretto. Verifica i caratteri inseriti."
        )
      )
    } else {
      // rearrange country code and check digits, and convert chars to ints
      digits = (code[3] + code[1] + code[2]).replace(
        /[A-Z]/g,
        function (letter) {
          return letter.charCodeAt(0) - 55
        }
      )
      // final check
      let checksum = digits.slice(0, 2)
      let fragment
      for (let offset = 2; offset < digits.length; offset += 7) {
        fragment = String(checksum) + digits.substring(offset, offset + 7)
        checksum = parseInt(fragment, 10) % 97
      }
      if (isNaN(checksum) || checksum !== 1) {
        reject(
          new Error(
            "L'Iban inserito non è corretto. Verifica i caratteri inseriti."
          )
        )
      }
    }

    // controllo abi/cab backend
    let abi = iban.substr(5, 5)
    let cab = iban.substr(10, 5)

    let paramObj = {
      abi: abi,
      cab: cab
    }
    let token = localStorage.getItem('token')
    if (!token) {
      return
    }
    // INIZIO SA/870 Gestione Iban Esteri - offerta fibra
    if (code[1] === 'IT' || code[1] === 'SM') {
      axiosACN
        .post('/sorgeniadataclient/isvalidabicab', paramObj, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then((resp) => {
          if (typeof resp.status !== 'undefined') {
            if (resp.data.status.toUpperCase() === 'KO') {
              reject(
                new Error(
                  'Non è possibile inserire Iban associati a carte prepagate. Inserisci un altro Iban.'
                )
              )
            } else {
              resolve(true)
            }
          } else {
            reject(
              new Error(
                'Non è possibile inserire Iban associati a carte prepagate. Inserisci un altro Iban.'
              )
            )
          }
        })
        .catch(() => {
          reject(
            new Error(
              'Non è possibile inserire Iban associati a carte prepagate. Inserisci un altro Iban.'
            )
          )
        })
    } else {
      resolve(true)
    }
    // FINE SA/870 Gestione Iban Esteri - offerta fibra
  })
}

const validateIdentityDocument = (value, vm) => {
  if (vm.nationality === 'Italia') {
    if (vm.type === 'card') {
      const electronicIC = /^C[a-zA-Z]{1}[0-9]{5}[a-zA-Z]{2}$/i
      const paperIC = /^[a-zA-Z]{2}[0-9]{7}$/i
      const paperICReverse = /^[0-9]{7}[a-zA-Z]{2}$/i
      return (
        paperIC.test(value) ||
        electronicIC.test(value) ||
        paperICReverse.test(value)
      )
    } else {
      const passport = /^[a-zA-Z]{2}[0-9]{7}$/
      return passport.test(value)
    }
  } else {
    return true
  }
}

const validateIdentityCard = (value) => {
  const electronicIC = /^C[a-zA-Z]{1}[0-9]{5}[a-zA-Z]{2}$/i
  const paperIC = /^[a-zA-Z]{2}[0-9]{7}$/i

  return paperIC.test(value) || electronicIC.test(value)
}

const validatePassport = (value) => {
  const passport = /^[a-zA-Z]{2}[0-9]{7}$/
  return passport.test(value)
}

const getMonth = (dateString) => {
  if (!dateString || typeof dateString !== 'string') {
    return ''
  }

  const monthNum = dateString.split('/')[1]

  return Consts.MONTHS[monthNum]
}
const getYear = (dateString) => {
  if (!dateString || typeof dateString !== 'string') {
    return ''
  }

  const year = dateString.split('/')[2]

  return year
}

const getTotal = (value, value1, value2) => {
  // permette di calcolare il totale e formattare un dato così x.xxx,xx
  // o di eseguire la differenza tra due valori e formattarli allo stesso modo
  let total = 0
  if (value == '') {
    total = value1 - value2
  } else {
    total = value
  }
  total = isNaN(total) ? 0 : total

  if (total > 0) {
    return total.toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  return total
}

const isHoliday = (month, day) => {
  const holidays = {
    newYear: { month: 0, day: 1 },
    epifania: { month: 0, day: 6 },
    liberazione: { month: 3, day: 25 },
    lavoro: { month: 4, day: 1 },
    repubblica: { month: 5, day: 2 },
    ferragosto: { month: 7, day: 15 },
    santi: { month: 10, day: 1 },
    immacolata: { month: 11, day: 8 },
    christmas: { month: 11, day: 25 },
    sstefano: { month: 11, day: 26 }
  }

  const currentMonth = month || new Date().getMonth()
  const currentDay = day || new Date().getDate()

  for (let h in holidays) {
    if (holidays[h].month === currentMonth && holidays[h].day === currentDay) {
      return true
    }
  }

  return false
}

const objectCopy = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (err) {
    console.error('Error during object copy:')
    console.error(err)
    return ''
  }
}

const validDateSupply = (value) => {
  if (value === '') {
    return false
  } else {
    let d
    value = value.split('/').reverse().join('-') // light format must be reworked
    d = new Date(value)
    return d.getFullYear() > 2000
  }
}

const validDate = (value) => {
  if (!value) {
    return false
  } else {
    let d = new Date(value)

    if (isNaN(d.getTime())) {
      value = value.split('/').reverse().join('-') // light format must be reworked
      d = new Date(value)
    }

    return d.getFullYear() > 2000
  }
}
const formatDate = () => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }
  var d = new Date()
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('/')
}
const formatDateItaly = () => {
  function pad(s) {
    return s < 10 ? '0' + s : s
  }
  var d = new Date()
  return [d.getDate(), pad(d.getMonth() + 1), pad(d.getFullYear())].join('/')
}

const formatTrackingTimelineDate = (date) => {
  if (date) {
    return date.split('/').reverse().join('.')
  }
}

const formatdateMonthYear = (value) => {
  let monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ]
  if (value) {
    const dateSplit = value.split('/')
    return monthNames[parseInt(dateSplit[1]) - 1] + ' ' + dateSplit[2]
  }
}

const getMillisFromStringDate = (str) => {
  return str ? new Date(str.split('/').reverse().join('-')).getTime() : null
}

const capitalize = (str, preserveUpperCase = false) => {
  if (!str || typeof str !== 'string') return

  if (!preserveUpperCase) {
    str = str.toLowerCase()
  }

  str = str.trim()
  str = str.split(' ')

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i] ? str[i][0].toUpperCase() + str[i].substr(1) : ''
  }

  return str.join(' ')
}

const getBase64PublicDoc = (pdfUrl) => {
  return new Promise(function (resolve, reject) {
    axios({
      url: pdfUrl,
      method: 'GET',
      responseType: 'blob'
    }).then((resp) => {
      var reader = new FileReader()
      reader.readAsDataURL(resp.data)
      reader.onloadend = function () {
        var base64data = reader.result

        resolve(base64data)
      }
    })
  })
}

const b64ToBlob = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => {
      return res.arrayBuffer()
    })
    .then((buf) => {
      return new File([buf], filename, {
        type: mimeType
      })
    })
}

const isOnIOSDevice = () => {
  // Simple check by user agent.
  function isIOSByUA() {
    return (
      /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(
        navigator.userAgent
      ) && !window.MSStream
    )
  }
  // Simple check by platform.
  function isIOSByPlatform() {
    return (
      !!navigator.platform &&
      (/iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(
        navigator.platform
      ) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
    )
  }

  // iPhone model checks by resolution and pixel ratio.
  function getIPhoneModel() {
    if (
      window.screen.height / window.screen.width === 812 / 375 &&
      window.devicePixelRatio === 3
    ) {
      return 'iPhone X'
    } else if (
      window.screen.height / window.screen.width === 736 / 414 &&
      window.devicePixelRatio === 3
    ) {
      return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus'
    } else if (
      window.screen.height / window.screen.width === 667 / 375 &&
      window.devicePixelRatio === 3
    ) {
      return 'iPhone 6 Plus, 6s Plus, 7 Plus or 8 Plus (display zoom)'
    } else if (
      window.screen.height / window.screen.width === 667 / 375 &&
      window.devicePixelRatio === 2
    ) {
      return 'iPhone 6, 6s, 7 or 8'
    } else if (
      window.screen.height / window.screen.width === 1.775 &&
      window.devicePixelRatio === 2
    ) {
      return 'iPhone 5, 5C, 5S, SE or 6, 6s, 7 and 8 (display zoom)'
    } else if (
      window.screen.height / window.screen.width === 1.5 &&
      window.devicePixelRatio === 2
    ) {
      return 'iPhone 4 or 4s'
    } else if (
      window.screen.height / window.screen.width === 1.5 &&
      window.devicePixelRatio === 1
    ) {
      return 'iPhone 1, 3G or 3GS'
    }
    // It can be something another
    return null
  }

  // iPad model checks by resolution and pixel ratio.
  function getIPadModel() {
    if (window.screen.height / window.screen.width === 1024 / 768) {
      if (window.devicePixelRatio === 1) {
        return 'iPad 1, iPad 2, iPad Mini 1'
      } else {
        return 'iPad 3, iPad 4, iPad 5, iPad Air 1, iPad Air 2, iPad Mini 2, iPad Mini 3, iPad Mini 4, iPad Pro 9.7'
      }
    } else if (window.screen.height / window.screen.width === 1112 / 834) {
      return 'iPad Pro 10.5'
    } else if (window.screen.height / window.screen.width === 1366 / 1024) {
      return 'iPad Pro 12.9, Pro 12.9 (2nd Gen)'
    }
    // It can be something another.
    return null
  }

  // Main combine check.
  let isIOS = isIOSByUA() || isIOSByPlatform()
  if (isIOS) {
    return true
  } else {
    // Need to try check by resolution.
    let iPhoneVersion = getIPhoneModel()
    let iPadVersion = getIPadModel()
    return iPhoneVersion || iPadVersion
  }
}

const isOnMobileDeviceGetPlatform = () => {
  let isMobileDevice = false
  let platform = null
  let md = new MobileDetect(navigator.userAgent)
  let os = md.os()
  if (os) {
    switch (os) {
      case 'AndroidOS':
        isMobileDevice = true
        platform = 'android'
        break
      case 'iOS':
        isMobileDevice = true
        platform = 'ios'
        break
    }
  } else if (md.match('Intel Mac OS X') && isOnIOSDevice()) {
    isMobileDevice = true
    platform = 'ios'
  }

  return isMobileDevice ? platform : false
}

const checkMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]
  const deviceWidth = window.innerWidth <= 480

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem) && deviceWidth
  })
}

const isValidEmail = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

const hasCharacterValid = (value) => {
  if (value === '') {
    return true
  }
  let format = /^[a-zA-Z0-9]+$/
  return format.test(value)
}

const getTransactionLabel = (transaction) => {
  let label = ''
  const event = transaction.event
  const action = transaction.action

  switch (event + '_' + action) {
    case '1_22':
      label = 'Cessazione'
      break
    case '1_23':
      label = 'Disiscrizione'
      break
    case '1_11':
      label = 'Benvenuto nella community Greeners!'
      break
    case '5_51':
      label = 'Hai partecipato al Bonus per te'
      break
    case '6_61':
      label = "Hai partecipato all'Instant Win"
      break
    case '6_63':
      label = "Non hai vinto con l'Instant Win"
      break
    case '6_62':
      label = "Hai vinto un premio con l'Instant Win"
      break
    case '2_21':
      label = 'Accesso'
      break
    case '3_31':
      label = 'Hai rinnovato la tua iscrizione a Greeners'
      break
    case '9_92':
      label = 'Hai richiesto un premio'
      break
    case '1200_1211':
      label = 'È il tuo compleanno'
      break
    case '1500_1507':
      label = 'Hai effettuato il test Carbon Footprint'
      break
    case '1500_1504':
    case '1500_1541':
    case '1500_1542':
    case '1500_1543':
    case '1500_1544':
    case '1500_1545':
      label =
        'Hai risposto al sondaggio "' + transaction.detail3 + '"' || 'Survey'
      break
    case '1500_1506':
    case '1500_1561':
    case '1500_1562':
    case '1500_1563':
    case '1500_1564':
    case '1500_1565':
      label = 'Hai caricato la foto per "' + transaction.detail3 + '"'
      break
    case '1500_1508':
      label = 'Hai caricato la foto per "' + transaction.detail3 + '"'
      break
    case '1500_1503':
    case '1500_1531':
    case '1500_1532':
    case '1500_1533':
    case '1500_1534':
    case '1500_1535':
      label = 'Hai risposto al quiz "' + transaction.detail3 + '"'
      break
    case '1500_1505':
    case '1500_1551':
    case '1500_1552':
    case '1500_1553':
    case '1500_1554':
    case '1500_1555':
      label = 'Hai guardato il video "' + transaction.detail3 + '"'
      break
    case '1400_1407':
      label = "Hai eseguito un'autolettura"
      break
    case '1400_1406':
      label = 'Hai ricevuto i punti fedeltà del mese'
      break
    case '1400_1408':
      label = 'Hai fatto una ricarica MyNextMove'
      break
    case '7_141':
      label = 'Hai risposto al sondaggio'
      break
    case '7_140':
      label = 'Iniziativa natalizia – The Garden'
      break
    case '1700_1701':
      label = 'Aggiornamento tipologia pagamento'
      break
    case '1700_1702':
      label = 'Aggiornamento tipologia fatturazione'
      break
    case '1700_1703':
      label = 'Attivazione contratto residenziale'
      break
    case '14_141':
      label = 'Hai portato un amico in Greeners'
      break
  }

  return label
}

const isValidMigrationCode = (code) => {
  if (isTelecomCode(code)) {
    let lenghtCodeOk = code && code.length >= 10 && code.length <= 19
    let resource = code.slice(3, 12)
    let resourceStartByZero = resource[0] === '0'

    return (
      lenghtCodeOk &&
      resourceStartByZero &&
      isValidMigrationCodeStandard(code, true)
    )
  }
  return isValidMigrationCodeStandard(code)
}

const isValidMigrationCodeStandard = (code, isTelecom) => {
  let evenMapping = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25
  }
  let oddMapping = {
    0: 1,
    1: 0,
    2: 5,
    3: 7,
    4: 9,
    5: 13,
    6: 15,
    7: 17,
    8: 19,
    9: 21,
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23
  }
  let controlCharMapping = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'I',
    9: 'J',
    10: 'K',
    11: 'L',
    12: 'M',
    13: 'N',
    14: 'O',
    15: 'P',
    16: 'Q',
    17: 'R',
    18: 'S',
    19: 'T',
    20: 'U',
    21: 'V',
    22: 'W',
    23: 'X',
    24: 'Y',
    25: 'Z'
  }

  if (!isTelecom) {
    if (!code || code.length < 15) {
      return false
    }
  }

  let kResourceIdMinLenght = isTelecom ? 7 : 8
  let kResourceIdMaxLenght = isTelecom ? 16 : 12
  let kCOWLenght = 3
  let kCOSLenght = isTelecom ? 0 : 3
  let kCCLenght = 1

  // let cow = code.slice(0, kCOWLenght)
  // let cos = code.slice(0, -1).substr(-3)
  let resourceId = code.slice(kCOWLenght, -(kCOSLenght + kCCLenght))
  let cc = code.substr(-1)

  let arrayCode = [...code]
  let values = []
  let rest = ''

  if (!isTelecom) {
    let corPrefix = code.slice(3, 5)
    let cos = code.slice(0, -1).substr(-3)
    if (corPrefix === 'FW') {
      if (cos.slice(0, 2) !== 'FW' && cos.slice(0, 2) !== 'OF') return false
    }
  }

  arrayCode.forEach((c, i) => {
    if (i < kCOWLenght + resourceId.length) {
      let value =
        (i + 1) % 2 === 0
          ? evenMapping[c.toUpperCase()]
          : oddMapping[c.toUpperCase()]
      if (value) {
        values.push(value)
      } else {
        return false
      }
    } else {
      return false
    }
  })

  if (
    values.length >= kCOWLenght + kResourceIdMinLenght &&
    values.length <= kCOWLenght + kResourceIdMaxLenght
  ) {
    let sum = values.reduce((ret, val) => ret + val)
    rest = sum % 26
  } else {
    return false
  }

  let calculatedCC = controlCharMapping[rest]

  if (calculatedCC) {
    return cc === calculatedCC
  }

  return false
}

const isTelecomCode = (code) => {
  let cow = code.slice(0, 3)
  return telecomCow.includes(cow)
}

const isFastwebCode = (code) => {
  return code.slice(3, 5) === 'FW'
}

const chunkArray = (array, size) => {
  const chunkedArray = []
  let index = 0
  while (index < array.length) {
    chunkedArray.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArray
}

const getKitSlideRouteName = (template, isMobile) => {
  let capitalizedFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  return isMobile ? 'public' + capitalizedFirstLetter(template) : template
}

const normalizedKitTemplateName = (template) => {
  let lowerFirstLetter = (text) => {
    return text.charAt(0).toLowerCase() + text.slice(1)
  }

  return lowerFirstLetter(template.replace('public', ''))
}

const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

const stripHtml = (input) => {
  var tmp = document.createElement('DIV')
  tmp.innerHTML = htmlDecode(input)
  return tmp.textContent || tmp.innerText || ''
}

const uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0
    var v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const dynamicSort = (property) => {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

const dynamicSortCresc = (property) => {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    return a[property] - b[property]
  }
}

const createDateFromACNFormat = (string) => {
  if (!string) return 0
  return new Date(string.split('/').reverse().join('-')).getTime()
}

const replaceDecimalPoint = (value) => {
  if (value) {
    if (typeof value === 'number') {
      value = String(value)
      let decimals = ''
      let parts = value.split('.')
      parts[1] = parts[1] || ''

      if (parts[1].length === 0) {
        decimals = '00'
      } else if (parts[1].length === 1) {
        decimals = parts[1] + '0'
      } else if (parts[1].length === 2) {
        decimals = parts[1]
      } else {
        decimals = parts[1][0] + parts[1][1]
      }

      return parts[0] + ',' + decimals
    } else if (value.indexOf('.') === -1) {
      return value + ',00'
    } else {
      value = value + '00'
      value = value.substring(0, value.indexOf('.') + 3)
      return value.toString().replace('.', ',')
    }
  } else {
    return ''
  }
}
const capitalizeFirstLetter = (string) => {
  if (string) {
    return (
      string.substring(0, 1).toUpperCase() +
      string.substring(1, string.length).toLowerCase()
    )
  } else return ''
}
function maskIban(iban) {
  if (iban) {
    let maskedIban = '****' + iban.slice(-4)
    return maskedIban
  } else {
    return ''
  }
}
export {
  b64ToBlob,
  capitalize,
  checkIban,
  checkMobile,
  chunkArray,
  createDateFromACNFormat,
  dynamicSort,
  dynamicSortCresc,
  formatDate,
  formatDateItaly,
  formatTrackingTimelineDate,
  formatdateMonthYear,
  getBase64PublicDoc,
  getKitSlideRouteName,
  getMillisFromStringDate,
  getMonth,
  getRequiredValidationError,
  getTransactionLabel,
  getYear,
  hasCharacterValid,
  htmlDecode,
  isFastwebCode,
  isHoliday,
  getTotal,
  isOnIOSDevice,
  isOnMobileDeviceGetPlatform,
  isTelecomCode,
  isValidEmail,
  isValidMigrationCode,
  isValidMigrationCodeStandard,
  normalizedKitTemplateName,
  objectCopy,
  replaceDecimalPoint,
  stripHtml,
  uuidv4,
  validDate,
  validDateSupply,
  validateCF,
  validateCFPI,
  validateClientCode,
  validateIban,
  validateIdentityCard,
  validateIdentityDocument,
  validatePassport,
  capitalizeFirstLetter,
  maskIban
}
