<template>
  <div class="resident">
    <div class="resident__btn" @click="next(true)">
      <h5>Si, sono residente</h5>
    </div>
    <div class="resident__btn mb-10" @click="next(false)">
      <h5>No, non sono residente</h5>
    </div>
    <div class="resident__box">
      <div class="BodyXL-Strong">Perchè te lo chiediamo</div>
      <div class="BodyL">
        Capire se sei residente o meno ci aiuta a definire la tassazione
        corretta per la tua offerta.
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'newResident',
  computed: {
    ...mapGetters('upsellingComm', [
      'flowType',
      'commodityType',
      'estimationType'
    ])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    next(isResident) {
      this.setSupplyDetailRoot({ isResident: isResident })
      if (this.commodityType === 'gas') {
        if (this.estimationType === 'noBill') {
          this.$emit('nextStep', 'upsellingcom_homeuse_open')
        } else {
          if (this.flowType === 'migration') {
            this.$emit('goToStep', 8, 'upsellingcom_currentprovider_open')
          } else {
            this.$emit('goToStep', 9, 'upsellingcom_supplydetail_open')
          }
        }
      } else if (this.flowType === 'migration') {
        if (this.estimationType === 'noBill') {
          this.$emit('nextStep', 'upsellingcom_homeuse_open')
        } else {
          this.$emit('goToStep', 8, 'upsellingcom_currentprovider_open')
        }
      } else if (this.flowType === 'reactivation') {
        this.$emit('goToStep', 9, 'upsellingcom_supplydetail_open')
      } else if (this.flowType === 'newConnection') {
        this.$emit('goToStep', 9, 'upsellingcom_supplydetail_open')
      }
      this.trackTapEvent({
        name: isResident
          ? 'upsellingcom_isresident_tap'
          : 'upsellingcom_isnotresident_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.resident {
  margin-top: 30px;
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 54px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 16px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
    .BodyL {
      margin-top: 16px;
    }
  }
}
</style>
