<template>
  <div class="page">
    <div class="HeadingXXL">Modifica metodo di pagamento</div>
    <div class="HeadingL mt-10">Seleziona un nuovo metodo di pagamento</div>
    <div v-if="!loadingMethods && !loadingClientPM">
      <img
        v-if="
          !methodsList.length ||
          (methodsList.length && !filteredMethodList.length)
        "
        class="mt-6"
        src="@/assets/payment-method/no-payment-methods.svg"
      />
      <div class="launchersSection">
        <ListLauncher :listLauncher="launcherList" @redirecting="redirecting" />
      </div>
    </div>
    <div @click="$emit('addOption')" class="addMetodPayment">
      <img src="@/assets/bluePlus.svg" />
      <div class="ButtonL">Aggiungi nuovo metodo di pagamento</div>
    </div>
    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      :cta1="cta1ServiceError"
      :cta2="cta2ServiceError"
      @retry="loadMethods"
      @returnToHome="returnToUser"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import ListLauncher from '../../../components/global/ListLauncher.vue'
import ServiceUnavailableDialog from '@/components/global/ServiceUnavailableDialog.vue'
import Consts from '@/js/constants'
import { eventBus } from '@/main'
import { maskIban } from '@/js/utils'
export default {
  name: 'paymentMethod',
  props: ['client', 'cancelOption'],
  components: {
    ListLauncher,
    ServiceUnavailableDialog
  },
  data() {
    return {
      loadingClientPM: true,
      loadingMethods: true,
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      serviceUnavailableDialogOpen: false,
      titleServiceError: '',
      bodyServiceError: '',
      imageServiceError: '',
      cta1ServiceError: 'Riprova',
      cta2ServiceError: "Torna all'utenza"
    }
  },
  computed: {
    ...mapGetters({ supplyDetail: 'supply/supplyDetail' }),
    paymentMethod: {
      get() {
        return this.$store.getters['upsellingFiber/paymentMethod']
      },
      set(newVal) {
        this.setPaymentMethod(newVal)
        if (newVal) this.$emit('openConfirmDialog')
      }
    },
    filteredMethodList() {
      if (
        this.methodsList.length &&
        this.supplyDetail &&
        this.supplyDetail[this.$route.params.supplyCode] &&
        this.supplyDetail[this.$route.params.supplyCode]['mdp']
      ) {
        let activeMethod =
          this.supplyDetail[this.$route.params.supplyCode]['mdp'][0] || {}
        return this.methodsList.filter((meth) => {
          if (meth.iban) {
            return meth.iban !== activeMethod.iban
          } else if (meth.creditCardNumber) {
            return (
              meth.creditCardNumber !== activeMethod.numeroCarta &&
              new Date() <
                new Date(
                  ...meth.creditCardExpirationDate
                    .split('/')
                    .reverse()
                    .concat('0')
                )
            )
          }
          return false
        })
      } else {
        return this.methodsList
      }
    },
    launcherList() {
      let launcherList = []
      let filteredMethodList = this.filteredMethodList
      filteredMethodList.forEach((meth) => {
        if (meth.iban) {
          launcherList.push({
            label: 'Addebito in conto corrente ' + maskIban(meth.iban),
            redirect: meth
          })
        }

        if (meth.methodType === this.PAYMENT_METHODS.CREDIT_CARD) {
          launcherList.push({
            label: 'Carta di credito ' + meth.creditCardNumber,
            redirect: meth
          })
        }
      })
      return launcherList
    }
  },
  watch: {
    cancelOption() {
      if (this.cancelOption === true) {
        this.paymentMethod = null
        this.$emit('resetOption')
      }
    },
    paymentMethod(value) {
      this.$emit('update:paymentOptionData', value)
    },
    supplyDetail: {
      handler: function (val) {
        if (val[this.$route.params.supplyCode]['mdp'][0])
          this.loadingClientPM = false
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // TODO maybe we should have a common flow for all 3 commodities. OR we should create separate one for gas& light. OR shouldn't
    ...mapMutations('upsellingFiber', ['setPaymentMethod']),
    ...mapActions('payment', ['getMethods']),
    loadMethods() {
      if (this.client) {
        this.getMethods({ clientCode: this.client.clientOwnerCode })
          .then((methods) => {
            this.methodsList = methods.filter(
              (meth) =>
                meth.methodType === this.PAYMENT_METHODS.CREDIT_CARD ||
                meth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT
            )
            this.loadingMethods = false
          })
          .catch((error) => {
            this.serviceUnavailableDialogOpen = true
            this.titleServiceError = 'Servizio temporaneamente non disponibile'
            this.bodyServiceError =
              'Siamo spiacenti ma per un errore temporaneo non è possibile modificare il metodo di pagamento. Ti invitiamo a riprovare.'
            this.imageServiceError = 'KO-500'
          })
      }
    },
    validatationHandler() {
      const result = !!this.paymentMethod
      eventBus.$emit('upsell-fiber:validation-completed', {
        component: 'paymentMethod',
        isValid: result
      })
      return result
    },
    redirecting(redirect) {
      this.setPaymentMethod(redirect)
      if (redirect) this.$emit('openConfirmDialog')
    },
    returnToUser() {
      this.$router.push({ name: 'supplyDetail' })
    }
  },
  created() {
    eventBus.$on('upsell-fiber:validate-inputs', this.validatationHandler)
  },
  beforeUnmount() {
    eventBus.$off('upsell-fiber:validate-inputs', this.validatationHandler)
  },
  beforeMount() {
    this.loadMethods()
  },
  mounted() {
    setTimeout(() => {
      this.loadingClientPM = false
    }, 4000)
  }
}
</script>
<style lang="scss" scoped>
.no_result {
  background-color: #f6f6f6;
  min-height: 150px;
  display: flex;
  justify-content: center;
  p {
    align-self: center;
    margin: 0;
  }
}
.launchersSection {
  margin-top: 20px;
  width: 652px;
}
.addMetodPayment {
  width: 652px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 26px;
  img {
    margin-right: 10px;
  }
}
.ButtonL {
  color: $srg-blue;
}
</style>
