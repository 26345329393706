<template>
  <div class="supplier">
    <div class="supplier__input">
      <div class="LabelM">FORNITORE ATTUALE</div>
      <v-combobox
        :items="getSupplyList"
        item-text="societa"
        placeholder="Es: ENI Gas e Luce"
        v-model="supplier"
        @blur="v$.supplier.$touch()"
        :error-messages="errorSupplier"
        return-object
        @update:search-input="onInputChange"
      ></v-combobox>
    </div>
    <div class="supplier__box">
      <div class="title">Perchè te lo chiediamo</div>
      <h5 class="desc">
        Selezionando il tuo precedente fornitore possiamo indicarti dove trovare
        i dati che ti verranno richiesti per la stima all'interno della
        bolletta.<br />
        Non preoccuparti, useremo i tuoi dati solo per farti avere una stima dei
        costi.
      </h5>
    </div>
    <MYSButton
      width="175"
      buttonClass="primaryButton"
      :disabled="v$.supplier.$invalid"
      @buttonClick="next"
      buttonText="Continua"
    />
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
import { debounce } from 'lodash'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'newCurrentSupplier',
  components: { MYSButton },
  data() {
    return {
      supplier: null,
      hint: ''
    }
  },
  watch: {
    'v$.supplier.$error': function (val) {
      val
        ? this.setValidationErrors('SUPPLIER_INVALID')
        : this.deleteValidationError('SUPPLIER_INVALID')
    }
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'supplyDetail',
      'commodityType',
      'getSupplyList'
    ]),
    errorSupplier() {
      let errArray = []

      if (!this.v$.supplier.$dirty) return errArray
      this.v$.supplier.required.$invalid === true &&
        errArray.push('Seleziona fornitore.')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'setSupplyDetailRoot',
      'setValidationErrors',
      'deleteValidationError'
    ]),
    ...mapActions('upsellingComm', ['retrieveSupplyList']),

    onInputChange: debounce(function (value) {
      if (value && value.length >= 3) {
        let contractType = this.commodityType === 'ele' ? 'ELE' : 'GAS'
        this.retrieveSupplyList({ contractType, hint: value })
      }
    }, 500),

    next() {
      this.setSupplyDetailRoot({ oldProvider: this.supplier })
      this.$emit('nextStep', 'upsellingcom_supplydetail_open')
    }
  },
  created() {
    if (this.supplyDetail.oldProvider) {
      this.supplier = this.supplyDetail.oldProvider
    }
  },
  validations: {
    supplier: { required }
  }
}
</script>
<style lang="scss" scoped>
.supplier {
  max-width: 652px;
  color: black;
  &__input {
    margin-top: 32px;
    margin-bottom: 18px;
  }
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .v-text-field {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .LabelM {
    color: $neutri-neutral-50;
  }
}
</style>
