<template>
  <div :class="'mb-8 message ' + type">
    <div class="text">
      <img :src="iconPath" alt="icon" />
      <div class="BodyL ml-3">{{ messageText }}</div>
    </div>
    <div v-if="showCTA" class="ButtonM" @click="ctaClick">Button</div>
  </div>
</template>

<script>
export default {
  name: 'MYSMessage',
  props: {
    type: {
      type: String,
      default: 'Informative'
    },
    showCTA: {
      type: Boolean,
      default: false
    },
    messageText: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconPath() {
      return require(`../../assets/global/${this.type}.svg`)
    }
  },
  methods: {
    ctaClick() {
      this.$emit('ctaClick')
    }
  }
}
</script>

<style scoped lang="scss">
.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
  .text {
    display: flex;
    align-items: center;
  }
  .ButtonM {
    color: $srg-blue;
    cursor: pointer;
    margin-left: 24px;
    height: 37px;
    padding: 10px;
    white-space: nowrap;
  }
}
.Informative {
  background-color: $neutri-neutral-20;
}
.Warning {
  background-color: $terziari-light-sea-green-20;
}
.Error {
  background-color: $feedback-error-20;
}
.Success {
  background-color: $terziari-dark-green-20;
}
</style>
