<template>
  <div class="requestType">
    <div class="requestType__box" @click="goToChangeSupplier">
      <div class="mb-1 LabelM">CAMBIO FORNITORE</div>
      <div class="BodyL">
        {{ commodityType === 'ele' ? 'Attualmente ho' : 'Ho' }} già una
        connessione e voglio cambiare fornitore in autonomia
      </div>
    </div>
    <div class="requestType__box" @click="goToReactivation">
      <div class="mb-1 LabelM">RIATTIVAZIONE</div>
      <div class="BodyL">
        Vorrei attivare l’utenza e il contatore è disattivato
      </div>
    </div>
    <div class="requestType__box" @click="goToNewConnection">
      <div class="mb-1 LabelM">NUOVO ALLACCIO</div>
      <div class="BodyL">
        Vorrei attivare l’utenza e il contatore non è allacciato
      </div>
    </div>

    <div class="vulnerable-user" v-if="$route.name === 'gasOffering'">
      <div class="BodyL">
        Sei un cliente vulnerabile?

        <span
          class="CaptionL color--blue ml-1"
          @click="vulnerabilityModal = true"
          style="cursor: pointer"
          >Scopri di più</span
        >
      </div>
    </div>
    <v-dialog
      v-if="vulnerabilityModal"
      max-width="750"
      v-model="vulnerabilityModal"
    >
      <v-row>
        <v-col class="dialog">
          <img
            @click="vulnerabilityModal = false"
            src="../../../../assets/icons/ic-close.svg"
          />
          <h2 class="mb-10 SubheadingL">Sei un cliente vulnerabile?</h2>
          <div class="description">
            Se possiedi almeno uno dei seguenti requisiti:

            <ul>
              <li>Benefici del bonus sociale economico</li>
              <li>Hai più di 75 anni</li>
              <li>
                Sei un soggetto con disabilità ai sensi della legge 104/92
              </li>
              <li>
                La fornitura di gas per la quale stai sottoscrivendo il
                contratto è ubicata in Strutture Abitative Emergenziali (SAE) a
                seguito di eventi calamitosi
              </li>
              <li>
                La fornitura di gas per la quale stai sottoscrivendo il
                contratto è ubicata in isole minori non interconnesse.
              </li>
              <li>
                A partire dal 1°gennaio 2024, puoi accedere al
                <a
                  class="ButtonM-Underline color--blue"
                  href="https://www.sorgenia.it/servizio-tutele-vulnerabilita-gas"
                  target="_blank"
                  >Servizio di Tutela della Vulnerabilità</a
                >
                che prevede condizioni economiche e contrattuali determinate
                dall’Autorità di regolazione per energia reti e ambiente.
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'newRequestType',
  data() {
    return {
      vulnerabilityModal: false
    }
  },
  methods: {
    ...mapGetters('upsellingComm', ['commodityType']),
    ...mapMutations('upsellingComm', [
      'setFlowType',
      'setTypeRequest',
      'resetSupplyDetail'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    goToChangeSupplier() {
      this.resetSupplyDetail()
      this.setTypeRequest('CA')
      this.$emit('setFlowType', 'changeSupplier')
      this.setFlowType('migration')
      this.$emit('goToStep', 3, 'upsellingcom_issupplyowner_open')
      this.trackTapEvent({
        name: 'upsellingcom_migration_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    },
    goToReactivation() {
      this.resetSupplyDetail()
      this.setTypeRequest('CO')
      this.$emit('setFlowType', 'reactivation')
      this.setFlowType('reactivation')
      this.$emit('goToStep', 6, 'upsellingcom_isresident_open')
      this.trackTapEvent({
        name: 'upsellingcom_reactivation_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    },
    goToNewConnection() {
      this.resetSupplyDetail()
      this.setTypeRequest('PR')
      this.$emit('setFlowType', 'newConnection')
      this.setFlowType('newConnection')
      this.$emit('goToStep', 6, 'upsellingcom_isresident_open')
      this.trackTapEvent({
        name: 'upsellingcom_newconnection_tap',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  background: white;
  padding: 40px;
  border-radius: 40px;
  position: relative;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .description {
    font-size: 18px;
    line-height: 1.6;
    color: black;
    margin-top: 0;
    margin-bottom: 32px;
  }
  img {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    &.preview {
      position: initial;
    }
  }
  .v-btn {
    font-size: 16px;
    letter-spacing: normal;
    text-transform: none;
  }
}
.requestType {
  margin-top: 30px;
  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 652px;
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    cursor: pointer;
    h5 {
      max-width: 600px;
    }
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
  }
  .vulnerable-user {
    margin-top: 36px;
  }
  .LabelM {
    color: $neutri-neutral-60;
  }
}
</style>
