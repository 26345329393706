<template>
  <div class="payment">
    <TcVars />
    <div class="payment__content">
      <template v-if="!newPaymentMethod">
        <h5 class="payment__info mb-6">
          Scegli dove addebitare mensilmente la fibra
        </h5>
        <div
          class="payment__btn"
          v-for="(meth, i) in methodsList"
          :key="i"
          @click="storePaymentMethod(meth, false)"
        >
          <h5 v-if="meth.methodType === PAYMENT_METHODS.CREDIT_CARD">
            Carta di credito {{ meth.creditCardNumber }}
          </h5>
          <h5 v-else>Conto corrente bancario {{ maskedIban(meth.iban) }}</h5>
        </div>
        <div
          class="payment__btn new"
          @click="newPaymentMethod = 'newPaySelection'"
        >
          <h5><strong>Nuovo metodo di pagamento</strong></h5>
        </div>
      </template>
      <template v-else-if="newPaymentMethod === 'newPaySelection'">
        <h5 class="payment__info mb-2">
          Scegli il metodo di pagamento alternativo desiderato.
        </h5>
        <h5 class="payment__desc mb-8">
          Ricorda che completando con successo il cambio del metodo di
          pagamento, la modifica verrà apportata solamente alla fornitura
          selezionata.
        </h5>
        <div class="payment__btn" @click="newPaymentMethod = 'newBankAccount'">
          <h5>Addebito in conto corrente</h5>
        </div>
        <div
          class="payment__btn mb-8"
          @click="newPaymentMethod = 'newCreditCard'"
        >
          <h5>Addebito carta di credito</h5>
        </div>
        <v-btn
          v-if="!editMode"
          text
          x-large
          color="primary"
          class="white ml-5"
          width="300"
          @click="newPaymentMethod = ''"
          >Annulla</v-btn
        >
      </template>
      <template v-else-if="newPaymentMethod === 'newBankAccount'">
        <v-text-field
          id="newIban"
          label="Nuovo Iban"
          class="auth__input"
          v-model.trim="newIban"
          :error-messages="newIbanErrors"
          @blur="v$.newIban.$touch()"
        ></v-text-field>
        <div class="d-flex align-center justify-space-between">
          <h5>L’intestatario del conto corrente è lo stesso della fornitura</h5>
          <v-switch v-model="sameHolder" color="#97D707" inset></v-switch>
        </div>
        <template v-if="!sameHolder">
          <v-text-field
            label="Nome intestatario"
            class="auth__input mb-4"
            v-model.trim="newIbanName"
            :error-messages="newIbanNameErrors"
            @blur="v$.newIbanName.$touch()"
          ></v-text-field>
          <v-text-field
            label="Cognome intestatario"
            class="auth__input mb-4"
            v-model.trim="newIbanSurname"
            :error-messages="newIbanSurnameErrors"
            @blur="v$.newIbanSurname.$touch()"
          ></v-text-field>
          <v-text-field
            label="Codice fiscale"
            class="auth__input mb-4"
            v-model.trim="newIbanCf"
            :error-messages="newIbanCfErrors"
            @blur="v$.newIbanCf.$touch()"
          ></v-text-field>
        </template>
        <v-btn
          color="primary"
          x-large
          width="260"
          class="v-btn--shadowed"
          @click="addPaymentMethod"
          >Aggiungi</v-btn
        >
        <v-btn
          text
          x-large
          color="primary"
          class="white ml-5"
          width="300"
          @click="newPaymentMethod = 'newPaySelection'"
          >Annulla</v-btn
        >
      </template>
    </div>
    <div v-show="showSiaFrame" class="siaDialog">
      <iframe
        id="fiberSiaCC"
        v-if="siaUrl"
        @load="iframeUrlChanged"
        :src="siaUrl"
        frameborder="0"
      ></iframe>
    </div>
    <PopupErrorIban ref="popup"></PopupErrorIban>
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import PopupErrorIban from '@/components/upselling/fiber/PopupErrorIban'
import Consts from '@/js/constants'
import { checkIban, getRequiredValidationError, validateCF } from '@/js/utils'
import { alphaNum, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { maskIban } from '@/js/utils'
import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'paymentComponent',
  props: ['editMode'],
  data() {
    return {
      methodsList: [],
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      paymentData: null,
      newPaymentMethod: null,
      sameHolder: true,
      newIban: '',
      newIbanName: '',
      newIbanSurname: '',
      newIbanCf: '',
      siaUrl: '',
      showSiaFrame: false
    }
  },
  watch: {
    newIbanCf(newVal) {
      this.newIbanCf = newVal.toUpperCase()
    },
    newIban(newVal) {
      this.newIban = newVal.toUpperCase().replace(' ', '')
    },
    newPaymentMethod(val, oldval) {
      if (val === '') {
        this.trackTapEvent({
          name: 'upsellingv2_mdpnew_undo',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else if (val === 'newCreditCard') {
        if (this.$store.getters['upsellingFiber/client']) {
          this.getDetailForClient({
            clientOwnerCode:
              this.$store.getters['upsellingFiber/client'].clientCode
          }).then((resp) => {
            this.getSiaUrl({ email: resp.contactMail }).then((url) => {
              this.siaUrl = url
              this.showSiaFrame = true
            })
            this.trackTapEvent({
              name: 'upsellingv2_mdpnewccr_tap',
              params: {
                client_selected:
                  this.$store.getters['upsellingFiber/addressCovered']
                    .clientOwnerCode || ''
              }
            })
          })
        } else {
          this.getSiaUrl({ email: this.clientDetails.contactMail }).then(
            (url) => {
              this.siaUrl = url
              this.showSiaFrame = true
            }
          )
          this.trackTapEvent({
            name: 'upsellingv2_mdpnewccr_tap',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        }
      } else if (val === 'newBankAccount') {
        this.trackTapEvent({
          name: 'upsellingv2_mdpnewbank_tap',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
      } else if (val === 'newPaySelection') {
        this.trackTapEvent({
          name: 'upsellingv2_NewPaymentMethod_open',
          params: {
            client_selected:
              this.$store.getters['upsellingFiber/addressCovered']
                .clientOwnerCode || ''
          }
        })
        if (oldval === 'newBankAccount') {
          this.trackTapEvent({
            name: 'upsellingv2_mdpnewbank_undo',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        } else if (oldval === 'newCreditCard') {
          this.trackTapEvent({
            name: 'upsellingv2_mdpnewccr_undo',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingv2_mdpnew_tap',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['client', 'provider']),
    newIbanErrors() {
      let errArray = []

      if (!this.v$.newIban.$dirty) return errArray

      this.v$.newIban.required.$invalid === true &&
        errArray.push("L'IBAN è obbligatorio")
      this.v$.newIban.alphaNum === false &&
        errArray.push('Formato IBAN non valido')
      this.v$.newIban.validateIban === false && errArray.push('IBAN errato')

      return errArray
    },
    newIbanNameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanName',
        'Il nome intestatario è obbligatorio'
      )
    },
    newIbanSurnameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanSurname',
        'Il cognome intestatario è obbligatorio'
      )
    },
    newIbanCfErrors() {
      let errArray = []

      if (!this.v$.newIbanCf.$dirty) return errArray

      this.v$.newIbanCf.required.$invalid === true &&
        errArray.push('Il codice fiscale intestatario è obbligatorio')
      this.v$.newIbanCf.validateCF === false &&
        errArray.push('Formato codice fiscale non valido')

      return errArray
    }
  },
  beforeMount() {
    // Get the existing payment methods list
    if (this.client) {
      this.getMethods({ clientCode: this.client.clientOwnerCode }).then(
        (methods) => {
          this.methodsList = methods.filter(
            (meth) =>
              meth.methodType === this.PAYMENT_METHODS.CREDIT_CARD ||
              meth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT
          )
        }
      )
    }
    if (this.editMode) {
      this.newPaymentMethod = 'newPaySelection'
    }
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setPaymentMethod']),
    ...mapMutations('upsellingFiber', ['setFlagNewIbanFiber']),
    ...mapActions('upsellingFiber', ['getSiaUrl']),
    ...mapActions('upsellingComm', ['checkIBAN']),
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('payment', ['getMethods']),
    ...mapActions('analytics', ['trackTapEvent']),
    addPaymentMethod(ccParams) {
      if (this.newPaymentMethod === 'newBankAccount') {
        this.paymentData = {
          businessName:
            this.newIbanName ||
            this.client.firstName + ' ' + this.newIbanSurname ||
            this.client.lastName,
          methodType: this.PAYMENT_METHODS.BANK_ACCOUNT,
          iban: this.newIban,
          ibanSubscriberFirstName: !this.sameHolder
            ? this.newIbanName
            : this.client.firstName,
          ibanSubscriberLastName: !this.sameHolder
            ? this.newIbanSurname
            : this.client.lastName,
          ibanSubscriberFiscalCode: !this.sameHolder
            ? this.newIbanCf
            : this.client.fiscalCode
        }
      } else if (this.newPaymentMethod === 'newCreditCard') {
        this.paymentData = {
          methodType: this.PAYMENT_METHODS.CREDIT_CARD,
          fiscalCode: this.client.fiscalCode,
          ...ccParams
        }
      }
      this.storePaymentMethod(this.paymentData, true)
    },
    storePaymentMethod(newMeth, isNewMethod) {
      if (
        newMeth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT &&
        isNewMethod
      ) {
        let isValidIban = checkIban(document.getElementById('newIban'))
        isValidIban
          .then((resp) => {
            this.methodsList.push(newMeth)

            this.setPaymentMethod(newMeth)
            this.setFlagNewIbanFiber(isNewMethod)
            if (!this.editMode) {
              if (this.provider === 'FW' || this.provider === 'FC') {
                this.$emit('nextStep', 'upsellingv2_shippingaddress_open')
              } else {
                this.$emit('goToStep', 11, 'upsellingv2_consent_open')
              }
            } else {
              this.$emit('prevStep')
            }
          })
          .catch((resp) => {
            this.$refs.popup.typeError(resp)
          })
      } else {
        this.setPaymentMethod(newMeth)
        if (newMeth.methodType === this.PAYMENT_METHODS.BANK_ACCOUNT) {
          this.trackTapEvent({
            name: 'upsellingv2_bank_tap',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingv2_mdpccr_tap',
            params: {
              client_selected:
                this.$store.getters['upsellingFiber/addressCovered']
                  .clientOwnerCode || ''
            }
          })
        }
        this.setFlagNewIbanFiber(isNewMethod)
        if (!this.editMode) {
          if (this.provider === 'FW' || this.provider === 'FC') {
            this.$emit('nextStep', 'upsellingv2_shippingaddress_open')
          } else {
            this.$emit('goToStep', 11, 'upsellingv2_consent_open')
          }
        } else {
          this.$emit('prevStep')
        }
      }
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.newPaymentMethod = 'newPaySelection'
        } else if (loc.indexOf('sia/done.html') > -1) {
          const queryParams = loc.substring(loc.indexOf('?') + 1).split('&')
          let queryParamsObj = {}
          queryParams.forEach((qp) => {
            const parts = qp.split('=')
            queryParamsObj[parts[0]] = parts[1]
          })

          const ccParams = {
            aliasPan: queryParamsObj.PANALIAS,
            creditCardCircuit: queryParamsObj.NETWORK,
            creditCardNumber:
              '****' + '****' + '****' + queryParamsObj.PANALIASTAIL,
            creditCardExpirationDate: queryParamsObj.PANALIASEXPDATE,
            crecurr: queryParamsObj.CRECURR,
            trecurr: queryParamsObj.TRECURR
          }
          this.addPaymentMethod(ccParams)

          this.siaUrl = ''
          this.showSiaFrame = false
        }
      } catch {
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    },
    maskedIban(iban) {
      return maskIban(iban)
    }
  },
  validations: {
    newIban: {
      required,
      alphaNum,
      validateIban: (value, vm) => {
        return vm.checkIBAN(value)
      }
    },
    newIbanName: { required },
    newIbanSurname: { required },
    newIbanCf: { required, validateCF }
  },
  components: {
    PopupErrorIban,
    TcVars
  }
}
</script>
<style lang="scss" scoped>
.payment {
  &__content {
    max-width: 652px;
  }

  color: black;

  &__info {
    font-weight: 500;
  }

  &__desc {
    line-height: 1.25;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;

    &::after {
      content: url('../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }

    &.new {
      color: #12256a;
    }
  }

  .siaDialog {
    height: 80vh;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
