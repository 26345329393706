<template>
  <div class="paymentEdit">
    <h3 class="mb-6">Modifica il metodo di pagamento</h3>
    <template v-if="paymentMethod.methodType !== PAYMENT_METHODS.CREDIT_CARD">
      <v-text-field
        id="newIban"
        label="Nuovo Iban"
        class="auth__input"
        v-model.trim="newIban"
        :error-messages="newIbanErrors"
        @blur="v$.newIban.$touch()"
      ></v-text-field>
      <div class="d-flex align-center justify-space-between">
        <h5>L’intestatario del conto corrente è lo stesso della fornitura</h5>
        <v-switch v-model="sameHolder" color="#97D707" inset></v-switch>
      </div>
      <template v-if="!sameHolder">
        <v-text-field
          label="Nome intestatario"
          class="auth__input mb-4"
          v-model.trim="newIbanName"
          :error-messages="newIbanNameErrors"
          @blur="v$.newIbanName.$touch()"
        ></v-text-field>
        <v-text-field
          label="Cognome intestatario"
          class="auth__input mb-4"
          v-model.trim="newIbanSurname"
          :error-messages="newIbanSurnameErrors"
          @blur="v$.newIbanSurname.$touch()"
        ></v-text-field>
        <v-text-field
          label="Codice fiscale"
          class="auth__input mb-4"
          v-model.trim="newIbanCf"
          :error-messages="newIbanCfErrors"
          @blur="v$.newIbanCf.$touch()"
        ></v-text-field>
      </template>
    </template>
    <div class="d-flex align-center mb-5">
      <div
        v-if="paymentMethod.methodType !== PAYMENT_METHODS.CREDIT_CARD"
        :class="`mr-4 btn-custom ${invalid ? 'disabled' : ''}`"
        @click="addPaymentMethod"
      >
        Modifica
      </div>
      <v-btn
        text
        x-large
        color="primary"
        class="white"
        width="370"
        @click="$emit('goToEdit')"
        >Aggiungi metodo di pagamento</v-btn
      >
    </div>
    <PopupErrorIban ref="popup"></PopupErrorIban>
  </div>
</template>
<script>
import PopupErrorIban from '@/components/upselling/fiber/PopupErrorIban'
import Consts from '@/js/constants'
import { checkIban, getRequiredValidationError, validateCF } from '@/js/utils'
import { alphaNum, required } from '@vuelidate/validators'
import { mapActions, mapMutations, mapGetters } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'paymentEdit',
  props: ['type'],
  data() {
    return {
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      paymentData: null,
      newPaymentMethod: null,
      sameHolder: true,
      newIban: '',
      newIbanName: '',
      newIbanSurname: '',
      newIbanCf: ''
    }
  },
  watch: {
    newIbanCf(newVal) {
      if (newVal) this.newIbanCf = newVal.toUpperCase()
    },
    newIban(newVal) {
      if (newVal) this.newIban = newVal.toUpperCase().replace(' ', '')
    },
    sameHolder() {
      if (
        (this.getFlagNewIbanFiber && this.type === 'fiber') ||
        (this.getFlagNewIban && this.type === 'commodity')
      ) {
        this.newIban = this.paymentMethod.iban
      }
      this.newIbanName = this.paymentMethod.ibanSubscriberFirstName
      this.newIbanSurname = this.paymentMethod.ibanSubscriberLastName
      this.newIbanCf = this.paymentMethod.ibanSubscriberFiscalCode
    }
  },
  beforeMount() {
    if (this.paymentMethod.methodType !== this.PAYMENT_METHODS.CREDIT_CARD) {
      if (
        (this.getFlagNewIbanFiber && this.type === 'fiber') ||
        (this.getFlagNewIban && this.type === 'commodity')
      ) {
        this.newIban = this.paymentMethod.iban
      }
      this.newIbanName = this.paymentMethod.ibanSubscriberFirstName
      this.newIbanSurname = this.paymentMethod.ibanSubscriberLastName
      this.newIbanCf = this.paymentMethod.ibanSubscriberFiscalCode
      this.sameHolder =
        this.client.fiscalCode === this.paymentMethod.ibanSubscriberFiscalCode
    }
  },
  computed: {
    ...mapGetters('upsellingFiber', ['getFlagNewIbanFiber']),
    ...mapGetters('upsellingComm', ['getFlagNewIban']),
    client() {
      if (this.type === 'commodity') {
        return this.$store.getters['upsellingComm/client']
      } else {
        return this.$store.getters['upsellingFiber/client']
      }
    },
    paymentMethod() {
      if (this.type === 'commodity') {
        return this.$store.getters['upsellingComm/paymentMethod']
      } else {
        return this.$store.getters['upsellingFiber/paymentMethod']
      }
    },
    invalid() {
      return (
        !this.newIban ||
        !this.newIbanName ||
        !this.newIbanSurname ||
        !this.newIbanCf
      )
    },
    newIbanErrors() {
      let errArray = []

      if (!this.v$.newIban.$dirty) return errArray

      this.v$.newIban.required.$invalid === true &&
        errArray.push("L'IBAN è obbligatorio")
      this.v$.newIban.alphaNum === false &&
        errArray.push('Formato IBAN non valido')
      this.v$.newIban.validateIban === false && errArray.push('IBAN errato')

      return errArray
    },
    newIbanNameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanName',
        'Il nome intestatario è obbligatorio'
      )
    },
    newIbanSurnameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanSurname',
        'Il cognome intestatario è obbligatorio'
      )
    },
    newIbanCfErrors() {
      let errArray = []

      if (!this.v$.newIbanCf.$dirty) return errArray

      this.v$.newIbanCf.required.$invalid === true &&
        errArray.push('Il codice fiscale intestatario è obbligatorio')
      this.v$.newIbanCf.validateCF === false &&
        errArray.push('Formato codice fiscale non valido')

      return errArray
    }
  },
  methods: {
    ...mapMutations('upsellingFiber', ['setPaymentMethod']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('upsellingComm', ['checkIBAN']),
    ...mapMutations('upsellingComm', ['setFlagNewIban']),
    addPaymentMethod() {
      this.trackTapEvent({
        name: 'upsellingv2_paymentmehtod_edit',
        params: { client_selected: this.client.clientOwnerCode || '' }
      })
      this.paymentData = {
        businessName:
          this.newIbanName ||
          this.client.firstName + ' ' + this.newIbanSurname ||
          this.client.lastName,
        methodType: this.PAYMENT_METHODS.BANK_ACCOUNT,
        iban: this.newIban,
        ibanSubscriberFirstName: !this.sameHolder
          ? this.newIbanName
          : this.client.firstName,
        ibanSubscriberLastName: !this.sameHolder
          ? this.newIbanSurname
          : this.client.lastName,
        ibanSubscriberFiscalCode: !this.sameHolder
          ? this.newIbanCf
          : this.client.fiscalCode,
        sameHolder: this.sameHolder
      }
      this.storePaymentMethod(this.paymentData, true)
    },
    storePaymentMethod(newMeth) {
      let isValidIban = checkIban(document.getElementById('newIban'))
      isValidIban
        .then((resp) => {
          this.setFlagNewIban(true)
          if (this.type === 'commodity') {
            this.$store.commit('upsellingComm/setPaymentMethod', {
              ...newMeth,
              sameHolder: this.sameHolder
            })
          } else {
            this.setPaymentMethod({ ...newMeth, sameHolder: this.sameHolder })
          }
          this.$emit('openAccordion', 'payment')
        })
        .catch((resp) => {
          this.$refs.popup.typeError(resp)
        })
    }
  },
  validations: {
    newIban: {
      required,
      alphaNum,
      validateIban: (value, vm) => {
        return vm.checkIBAN(value)
      }
    },
    newIbanName: { required },
    newIbanSurname: { required },
    newIbanCf: { required, validateCF }
  },
  components: {
    PopupErrorIban
  }
}
</script>
<style lang="scss" scoped>
.paymentEdit {
  padding-top: 20px;

  h3 {
    font-weight: 500;
  }

  .btn-custom {
    width: 242px;
    text-align: center;
    padding: 18px;
    color: black;
    border: 1px solid #ccd0e1;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 500;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
