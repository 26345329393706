<template>
  <div class="checkData">
    <!-- <div id="data" class="HeadingL mt-10 mb-4">Verifica dati</div> -->
    <div id="data" class="accordion mb-6">
      <div class="d-flex align-center justify-space-between">
        <div class="data">
          <div class="LabelM colorData mb-2">DATI FORNITURA</div>
          <div class="BodyL-Strong" v-if="code">
            {{ commodityType === 'ele' ? 'POD' : 'PDR' }}: {{ code }}
          </div>
          <div class="BodyL">{{ address.address }}</div>
        </div>
      </div>
    </div>
    <div id="payment" class="accordion mb-6">
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="LabelM colorData mb-2">METODO DI PAGAMENTO</div>
          <div class="BodyL">
            {{
              paymentMethod.methodType === PAYMENT_METHODS.CREDIT_CARD
                ? 'Carta di credito ' + paymentMethod.creditCardNumber
                : 'Conto corrente ' + checkIban()
            }}
          </div>
        </div>
        <div class="arrow" @click="openAccordion('payment')">
          <img src="../../../../assets/icons/chevron.svg" width="20" />
        </div>
      </div>
      <div class="content">
        <PaymentEdit
          @openAccordion="openAccordion"
          @goToEdit="$emit('goToEdit', commodityType === 'ele' ? 22 : 20, '')"
          type="commodity"
        />
      </div>
    </div>
    <div
      v-if="canAnticipate && typeRequest === 'CA'"
      id="anticipate"
      class="accordion mb-6"
    >
      <div class="d-flex align-center justify-space-between relative">
        <div class="data">
          <div class="BodyL-Strong mb-3">Voglio anticipare l’attivazione</div>
          <div class="BodyL pb-4">
            Utenza attiva dal {{ dac }} senza interruzioni
          </div>
        </div>
        <v-switch
          v-model="speedUpCheck"
          hide-details="true"
          class="switch"
          color="#70D736"
          inset
        ></v-switch>
      </div>
    </div>
    <div v-else id="anticipateStatic" class="accordion mb-6">
      <div class="d-flex align-center justify-space-between">
        <div class="data">
          <div class="BodyL-Strong mb-3">Tempi di attivazione</div>
          <div class="BodyL pb-4">
            Utenza attiva dal {{ dac }} senza interruzioni
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-center justify-space-between relative">
      <div class="data">
        <div class="BodyL-Strong mt-6 mb-2">Hai ricevuto un codice sconto?</div>
      </div>
    </div>
    <div>
      <div class="pt-3 mb-4">Inseriscilo e ricevi uno sconto in bolletta</div>
      <div class="mt-6 mb-3 custom-input-field">
        <v-text-field
          v-model="promoCode"
          label="Codice sconto"
          placeholder="inserisci codice"
          persistent-placeholder
          @input="v$.promoCode.$touch()"
          :error-messages="verifyCodeErrors"
        />
      </div>
    </div>

    <v-btn
      color="primary"
      x-large
      width="262"
      class="v-btn--shadowed"
      @click="confirm"
      >Confermo i dati</v-btn
    >
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PaymentEdit from '@/components/upselling/fiber/PaymentEdit'
import Consts from '@/js/constants'
import { capitalize, hasCharacterValid } from '@/js/utils'
import GenErr from '@/js/genericErrors'
import { useVuelidate } from '@vuelidate/core'
import { maskIban } from '@/js/utils'

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'checkData',
  data() {
    return {
      addressBillPaper: '',
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      promoCode: '',
      errorPromoCode: null
    }
  },
  watch: {
    verifyCodeErrors: function (val) {
      val.length > 0
        ? this.setValidationErrors('FRIEND_CODE_INVALID')
        : this.deleteValidationError('FRIEND_CODE_INVALID')
    },
    promoCode(newVal) {
      this.errorPromoCode = null
    }
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'address',
      'paymentMethod',
      'commodityType',
      'supplyDetail',
      'client',
      'typeRequest',
      'getInfoUpselling',
      'getFlagNewIban'
    ]),
    catalogList() {
      return this.getInfoUpselling.catalogList[0]
    },
    canAnticipate() {
      let currentDate = new Date()
      let batchDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        10
      ).getTime()
      let afterthoughtDate = batchDate - 14 * 24 * 60 * 60 * 1000
      if (currentDate.getDate() <= 10) {
        return true
      } else if (currentDate.getTime() > afterthoughtDate) {
        return true
      } else {
        return false
      }
    },
    speedUpCheck: {
      get() {
        return this.supplyDetail.anticipateActivation
      },
      set(val) {
        if (val) {
          this.trackTapEvent({
            name: 'upsellingcom_renounceafterthought_toggle',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected: this.client.clientOwnerCode || ''
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingcom_keepafterthought_toggle',
            params: {
              type: this.$store.getters['upsellingComm/commodityType'],
              client_selected: this.client.clientOwnerCode || ''
            }
          })
        }
        this.setSupplyDetailRoot({ anticipateActivation: val, dac: this.dac })
      }
    },
    code() {
      if (this.commodityType === 'ele') {
        return this.supplyDetail.ele.pod
      } else {
        return this.supplyDetail.gas.pdr
      }
    },
    verifyCodeErrors() {
      let errArray = []
      if (!this.v$.promoCode?.$dirty) return errArray

      this.v$.promoCode.hasCharacterValid.$invalid &&
        errArray.push('Non sono ammessi caratteri speciali')
      if (this.errorPromoCode) {
        if (this.errorPromoCode.errorCode === '001') {
          errArray.push('Codice non valido')
        } else if (this.errorPromoCode.errorCode === '002') {
          errArray.push('Listino non presente')
        }
      }

      return errArray
    },
    dac() {
      const computeMonthScad = (refDate, yearScad = 0) => {
        const currentDate = new Date()
        const limitDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          10
        )

        let month = null
        let year = yearScad

        if (refDate < limitDate.getTime()) {
          month = currentDate.getMonth() + 1
        } else {
          month = currentDate.getMonth() + 2
        }
        if (month > 11) {
          year = yearScad + 1
          month = month - 11 - 1
        }
        let retDate = new Date(year, month, 1)
        return (
          '01.' +
          (retDate.getMonth() + 1 < 10
            ? '0' + (retDate.getMonth() + 1)
            : retDate.getMonth() + 1) +
          '.' +
          year
        )
      }
      let now = new Date()
      let monthDate = new Date(now.getFullYear(), now.getMonth(), 1)
      let retDate
      if (this.speedUpCheck) {
        retDate = computeMonthScad(monthDate, monthDate.getFullYear())
      } else {
        retDate = computeMonthScad(
          monthDate.getTime() + 14 * 24 * 60 * 60 * 1000,
          monthDate.getFullYear()
        )
      }
      this.setSupplyDetailRoot({ dac: retDate })
      return retDate
    }
  },
  methods: {
    ...mapActions('upsellingComm', ['subscribe']),
    ...mapMutations('upsellingComm', [
      'setSupplyDetailRoot',
      'setInvoiceMethod',
      'setMgmDetail',
      'setValidationErrors',
      'deleteValidationError'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('MGM', ['verifyFriendCode']),
    checkIban() {
      return this.getFlagNewIban
        ? this.paymentMethod.iban
        : maskIban(this.paymentMethod.iban)
    },
    verify() {
      return this.verifyFriendCode({
        codiceAmico: this.promoCode,
        codicefiscaleCliente: this.client.fiscalCode,
        codiceCampagnaWeb: '',
        segmentoCliente: 'Residenziale Digital'
      })
        .then((res) => {
          this.errorPromoCode = res
          if (res.status === 'OK') {
            this.setMgmDetail({
              listinoMgm: res.listinoMgm,
              nomeCampagnaMgm: res.nomeCampagnaMgm,
              promoCode: this.promoCode
            })
            return true
          } else {
            return false
          }
        })
        .catch(() => {
          return false
        })
    },
    openAccordion(id) {
      document.getElementById(id).classList.toggle('open')
    },
    updateAddress() {
      this.trackTapEvent({
        name: 'upsellingcom_nodigitalbilladdress_edit',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.client.clientOwnerCode || ''
        }
      })
      this.addressBillPaper = capitalize(
        this.invoiceMethod.address.label +
          ' ' +
          this.invoiceMethod.number.fullInfo.NRPNUMCIV.lValue +
          ', ' +
          this.invoiceMethod.city.fullInfo.DSXOBJCNL
      )
      this.openAccordion('addressSentBill')
    },
    async confirm() {
      this.trackTapEvent({
        name: 'subscription_confirmation_clicked',
        params: {
          services_to_subscribe: this.commodityType,
          funnel_segment: this.client?.clientType?.toLowerCase(),
          payment_method_selected: this.paymentMethod.methodType.toLowerCase(),
          product_code: this.getInfoUpselling.productCode,
          product_name: this.getInfoUpselling.product.productTitle, //(arriva da servizio productTitle),
          price_list_code: this.catalogList.tariffList //(arriva da servizio tariffList),
        }
      })
      try {
        let verifyCheck = true
        if (this.promoCode) {
          verifyCheck = await this.verify()
        }
        if (verifyCheck) {
          if (
            this.errorPromoCode &&
            this.errorPromoCode.errorCode &&
            this.promoCode !== ''
          ) {
            return
          } else {
            this.subscribe()
              .then((resp) => {
                if (resp == 200) {
                  this.$router.push({ name: `subscribed${this.commodityType}` })
                  this.trackTapEvent({
                    name: 'subscription_completed',
                    params: {
                      services_to_subscribe: this.commodityType,
                      funnel_segment: this.client?.clientType?.toLowerCase(),
                      payment_method_selected:
                        this.paymentMethod.methodType.toLowerCase(),
                      product_code: this.getInfoUpselling.productCode,
                      product_name: this.getInfoUpselling.product.productTitle, //(arriva da servizio productTitle),
                      price_list_code: this.catalogList.tariffList //(arriva da servizio tariffList),
                    }
                  })
                }

                if (resp.status && resp.status >= 400) {
                  this.trackTapEvent({
                    name: 'subscription_confirmation_error',
                    params: {
                      services_to_subscribe: this.commodityType,
                      funnel_segment: this.client?.clientType?.toLowerCase(),
                      flow: 'subscription',
                      status: resp.data.status ? resp.data.status : 'KO',
                      error_code: resp.data.errorCode
                        ? resp.data.errorCode
                        : '',
                      error_description: resp.data.errorDescription
                        ? resp.data.errorDescription
                        : ''
                    }
                  })
                }
              })
              .catch((error) => {
                // We do not expect any business logic error, so show an alert with generic error
                this.trackTapEvent({
                  name: 'subscription_confirmation_error',
                  params: {
                    services_to_subscribe: this.commodityType,
                    funnel_segment: this.client?.clientType?.toLowerCase(),
                    flow: 'subscription',
                    status: error.status ? error.status : 'SERVER_ERROR',
                    error_code: error.error_code ? error.error_code : '',
                    error_description: error.error_description
                      ? error.error_description
                      : ''
                  }
                })
                GenErr.handleGenericError(error && error.message)
                GenErr.showErrorMessage(
                  'Si è verificato un errore durante la fase di sottoscrizione. Ti preghiamo di verificare i dati e riprovare. Grazie.'
                )
              })
          }
        } else {
          this.v$.promoCode.$touch()
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  components: {
    PaymentEdit
  },
  validations: {
    promoCode: { hasCharacterValid }
  }
}
</script>
<style lang="scss" scoped>
.colorData {
  color: #6e7689;
}
.checkData {
  max-width: 652px;
  color: black;
}
.accordion,
.sub-accordion {
  .relative {
    position: relative;
  }
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eaeef5;
  .arrow {
    img {
      transition: transform 0.2s ease-in;
      transform: rotate(0deg);
    }
  }
  &.open {
    .content {
      max-height: 800px;
    }
    .arrow {
      img {
        transition: transform 0.2s ease-in;
        transform: rotate(180deg);
      }
    }
  }
  .switch {
    position: absolute;
    right: 0;
    margin-top: auto;
  }
  .inputCode {
    width: 360px;
  }
  .bold {
    font-weight: 500;
  }
}
.sub-accordion {
  border-top: 1px solid #eaeef5;
  padding-top: 24px;
  padding-bottom: 0px;
  margin-top: 24px;
  border-bottom: none;
  &.open {
    .sub-content {
      padding-top: 20px;
      max-height: 800px;
    }
    .sub-arrow {
      img {
        transition: transform 0.2s ease-in;
        transform: rotate(180deg);
      }
    }
  }
}
.content,
.sub-content {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 0.7s;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>

<style lang="scss">
.checkData {
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      &::before {
        content: url('../../../../assets/icons/Error.svg');
        margin-right: 5px;
      }
    }
    input {
      color: red !important;
    }
  }
}
</style>
