<template>
  <div class="property">
    <div class="property__btn" @click="next('DP')">
      <h5>Diritto di proprietà</h5>
    </div>
    <div class="property__btn" @click="next('DU')">
      <h5>Diritto di usufrutto</h5>
    </div>
    <div class="property__btn" @click="next('CA')">
      <h5>Contratto di affitto/locazione</h5>
    </div>
    <div class="property__btn mb-10" @click="next('AT')">
      <h5>Altro titolo</h5>
    </div>
    <div class="property__box">
      <div class="BodyXL-Strong mb-4">
        Dichiarazione di legittima titolarità dell’immobile
      </div>
      <div class="BodyL">
        È la dichiarazione del titolo che attesta la proprietà, il regolare
        possesso o la regolare detenzione dell'unità immobiliare in favore della
        quale si richiede l'attivazione della fornitura di energia.<br />
        Il cliente attesta la legittima occupazione dell'immobile/degli immobili
        oggetto del Contratto in forza di: "Diritto di Proprietà - Diritto di
        Usufrutto - Contratto di Affitto/Locazione - Altro Titolo".
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex'
export default {
  name: 'NewPropertyOwner',
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetailRoot']),
    ...mapActions('analytics', ['trackTapEvent']),
    next(type) {
      this.setSupplyDetailRoot({ ownership: type })
      this.$emit('nextStep', 'upsellingcom_lightuse_open')
      // for now deactivated to sync with excel this.trackTapEvent({ name: `upsellingcom_titolarity${type}_tap`, params: { type: this.$store.getters['upsellingComm/commodityType'], client_selected: this.$store.getters['upsellingComm/address'] ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] || '' : 'not_selected' } })
    }
  }
}
</script>
<style lang="scss" scoped>
.property {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 54px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 16px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 15px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 8px;
    background-color: #f8f9fc;
    margin-bottom: 48px;
  }
}
</style>
