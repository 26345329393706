<template>
  <div class="quotation">
    <div class="d-flex align-start">
      <div class="skeletonUpselling" v-if="!loaded">
        <transition name="fade">
          <Placeholder type="upsellingPreventivo" />
        </transition>
        <transition name="fade">
          <Placeholder type="upsellingPreventivoNoBg" />
        </transition>
      </div>
      <transition class="fade">
        <div v-if="loaded">
          <div class="priceHeaderContainer mb-10">
            <div class="priceHeader">
              <div class="priceHeader__title">
                <div
                  v-if="product.priceQuoteText"
                  class="priceHeader__tip LabelS"
                >
                  {{ product.priceQuoteText }}
                </div>
                <div class="priceHeader__offertName">
                  <div class="HeadingL">
                    {{ product.productTitle }}
                  </div>
                  <img
                    contain
                    :src="
                      commodityType === 'ele'
                        ? require('../../../../assets/icons/ic-light-blu.svg')
                        : require('../../../../assets/icons/ic-fire-blu.svg')
                    "
                    width="32"
                    height="32"
                  />
                </div>
              </div>
              <div class="priceHeader__price">
                <div class="priceHeader__price__text">
                  <div class="LabelM">{{ i4c.monthlyExpensesTitle }}</div>
                  <div class="BodyM">
                    {{ product.priceDetail }}
                  </div>
                </div>
                <div class="priceHeader__price__number">
                  <div class="SubheadingXXL">{{ splitPrice.parteIntera }}</div>
                  <div class="BodyL-Strong mt-4">
                    ,{{ splitPrice.parteDecimale }}
                  </div>
                </div>
              </div>
            </div>
            <div
              @click="openHowCalculatedDetail()"
              class="ButtonM howCalculated ml-1"
            >
              {{ product.quantitativeModalTitle }}
            </div>
          </div>
          <div class="benefitsContainer ml-1">
            <div v-if="benefits.benefitList" class="BodyL-Strong mb-5">
              {{ benefits.benefitTitle }}
            </div>
            <div class="mb-7">
              <div
                v-for="(benefit, i) in benefits.benefitList"
                :key="i"
                class="benefits mb-4"
              >
                <div
                  v-if="
                    (commodityType === 'ele' && benefit.electricityFlag) ||
                    (commodityType === 'gas' && benefit.gasFlag)
                  "
                  class="benefit mb-3"
                >
                  <div class="benefit__icon">
                    <img
                      :src="loadIconClass(benefit.benefitIconClass)"
                      width="24"
                    />
                  </div>
                  <div class="BodyL" v-html="benefit.title"></div>
                  <div class="benefit__info">
                    <img
                      v-if="benefit.deepDiveModalFlag"
                      src="@/assets/upselling/icons/icon-info.svg"
                      width="24"
                      class="imgInfo"
                      @click="
                        openCustomDialog(
                          benefit.imageModal,
                          benefit.modalText,
                          benefit.modalTitle
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="ButtonM contractConditions mb-7">
              {{ catalogList.contractTermsText }}
              <img
                src="@/assets/upselling/icons/icon-download.svg"
                width="24"
                class="ml-2"
                @click="openDoc()"
              />
            </div>
            <div class="LabelM">{{ catalogList.endDateValidityFinalText }}</div>
          </div>
        </div>
      </transition>
    </div>

    <div class="cta" v-if="!emptyState">
      <MYSButton
        :disabled="!loaded"
        buttonText="Inizia la sottoscrizione"
        width="260"
        buttonClass="primaryButton primaryButtonL"
        @buttonClick="next()"
      />
      <div v-if="!emptyState">
        <h5 @click="goToSpeakWith" class="callMe">Parla con un consulente</h5>
      </div>
    </div>

    <PdfViewer
      :showDialog="dialogTermCond"
      :url="contractDataUrl"
      @closing-pdfViewer="dialogTermCond = false"
      :attachTo="'body'"
      height="600px"
    />
    <CustomDialog
      :customDialogOpen.sync="customDialogOpen"
      :body="bodyModal"
      :title="titleModal"
      :image="imageModal"
      :showImage="showImage"
    />
    <ServiceUnavailableDialog
      :serviceUnavailableDialogOpen.sync="serviceUnavailableDialogOpen"
      :imageResponse="imageServiceError"
      :title="titleServiceError"
      :body="bodyServiceError"
      cta1="Riprova ora"
      cta2="Torna alla homepage"
      @retry="loadOfferPreview"
      @returnToHome="returnToHome"
    />
  </div>
</template>
<script>
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CustomDialog from '@/components/upselling/gas&light/CustomDialog'
import ServiceUnavailableDialog from '@/components/global/ServiceUnavailableDialog.vue'
import MYSButton from '../../../../components/global/MYSButton.vue'
import Placeholder from '@components/global/Placeholder.vue'
import { map } from 'lodash'
import { f } from 'vuetify-google-autocomplete'

export default {
  name: 'priceQuotation',
  components: {
    PdfViewer,
    CustomDialog,
    ServiceUnavailableDialog,
    MYSButton
  },
  data() {
    return {
      quotes: null,
      documentLoaded: false,
      contractDataUrl: '',
      contractDataUrl12: '',
      contractDataUrl24: '',
      dialogTermCond: false,
      dialogDetail12: false,
      dialogDetail24: false,
      emptyState: false,
      loaded: false,
      customDialogOpen: false,
      imageModal: null,
      bodyModal: null,
      titleModal: null,
      showImage: false,
      serviceUnavailableDialogOpen: false,
      titleServiceError: '',
      bodyServiceError: '',
      imageServiceError: ''
    }
  },

  mounted() {
    this.loadOfferPreview()
    this.setIdTariffList(this.catalogList.idTariffList)
  },
  computed: {
    ...mapGetters('upsellingComm', [
      'commodityType',
      'estimationType',
      'supplyDetail',
      'address',
      'offer',
      'client',
      'typeRequest',
      'getInfoUpselling',
      'flowType'
    ]),
    ...mapGetters('offers', ['upsellingInfoComm']),
    ...mapGetters('account', ['accountProperties']),
    benefits() {
      return this.infoUpselling.benefits
    },
    product() {
      return this.infoUpselling.product
    },
    catalogList() {
      return this.infoUpselling.catalogList[0]
    },
    i4c() {
      return this.infoUpselling.i4c
    },
    infoUpselling() {
      return this.getInfoUpselling
    },
    splitPrice() {
      const [parteIntera, parteDecimale] = this.i4c.monthlyCost.split(',')
      return {
        parteIntera,
        parteDecimale
      }
    },

    upsellingInfo() {
      return this.upsellingInfoComm
    },
    dialogIndex: {
      get(month) {
        return this['dialogDetail' + month]
      }
    }
  },
  methods: {
    ...mapActions('upsellingComm', [
      'getExportPreventivi',
      'getListino',
      'retrieveInfoUpselling'
    ]),
    ...mapActions('offers', ['getDocumentCommodity']),
    ...mapMutations('upsellingComm', [
      'setExportedQuote',
      'setSelectedOffer',
      'setIdTariffList'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    openCustomDialog(imageModal, bodyModal, titleModal) {
      this.showImage = false
      this.imageModal = imageModal
      this.bodyModal = bodyModal
      this.titleModal = titleModal
      this.customDialogOpen = true
    },
    async loadOfferPreview() {
      try {
        let params = {}
        if (this.commodityType === 'ele') {
          params = {
            contractType: this.commodityType.toUpperCase(),
            quote: true,
            resident: this.supplyDetail.isResident,
            actualEleSupplierCode: this.supplyDetail?.oldProvider?.ID_SOCIETA
              ? this.supplyDetail.oldProvider.ID_SOCIETA
              : undefined,
            annualEleConsumption: this.supplyDetail.ele.consumption,
            meterPower: this.supplyDetail.ele.power,
            meterPowerOther:
              this.supplyDetail.ele.power === 'O'
                ? this.supplyDetail.ele.alternativePower
                : null
          }
        } else {
          params = {
            contractType: this.commodityType.toUpperCase(),
            quote: true,
            resident: this.supplyDetail.isResident,
            actualGasSupplierCode: this.supplyDetail?.oldProvider?.ID_SOCIETA
              ? this.supplyDetail.oldProvider.ID_SOCIETA
              : undefined,
            gasSupplyCityCode: this.address.codComune,
            annualGasConsumption: this.supplyDetail.gas.consumption
          }
        }
        await this.retrieveInfoUpselling(params)
        this.contractDataUrl = await this.getDocumentCommodity(
          this.catalogList.docName
        )
        this.loaded = true
        this.documentLoaded = true
        this.trackTapEvent({
          name: 'subscription_offer_viewed',
          params: {
            services_to_subscribe: this.commodityType,
            funnel_segment: this.client?.clientType?.toLowerCase(),
            product_code: this.infoUpselling.productCode,
            product_name: this.product.productTitle, //da chiedere
            price_list_code: this.catalogList.tariffList //da chiedere
          }
        })
      } catch (error) {
        console.error(error)
        this.serviceUnavailableDialogOpen = true
        this.titleServiceError = 'Servizio temporaneamente non disponibile'
        this.bodyServiceError =
          'Il servizio è temporaneamente non disponibile. Riprova ora oppure in un secondo momento.'
        this.imageServiceError = 'KO-500'
      }
    },
    loadIconClass(iconClass) {
      try {
        return require(`@/assets/upselling/icons/${iconClass}.svg`)
      } catch (error) {
        return require(`@/assets/upselling/icons/icon-fallback.svg`)
      }
    },
    openHowCalculatedDetail() {
      this.customDialogOpen = true
      this.imageModal = ''
      this.showImage = false
      this.bodyModal = this.product.quantitativeModal
      this.titleModal = this.product.quantitativeModalTitle
    },
    returnToHome() {
      this.$router.push({ name: 'home' })
    },
    next() {
      if (this.commodityType === 'gas') {
        if (this.canAnticipate() && this.typeRequest === 'CA') {
          this.$emit('goToStep', 18, 'upsellingcom_activationtype_open')
        } else {
          this.$emit('setFlowType', 'canNotAnticipate')
          this.$emit('goToStep', 20, 'notrack')
        }
      } else {
        if (this.canAnticipate() && this.typeRequest === 'CA') {
          this.$emit('goToStep', 20, 'upsellingcom_activationtype_open')
        } else {
          this.$emit('setFlowType', 'canNotAnticipate')
          this.$emit('goToStep', 22, 'notrack')
        }
      }
    },
    checkDataBenefit(benefit) {
      if (
        benefit.title !== null &&
        benefit.modalTitle !== null &&
        benefit.modalText !== null &&
        benefit.benefitIconClass !== null
      ) {
        return true
      } else {
        return false
      }
    },
    canAnticipate() {
      let currentDate = new Date()
      let batchDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        10
      ).getTime()
      let afterthoughtDate = batchDate - 14 * 24 * 60 * 60 * 1000
      if (currentDate.getDate() <= 10) {
        return true
      } else if (currentDate.getTime() > afterthoughtDate) {
        return true
      } else {
        return false
      }
    },

    openDoc() {
      this.dialogTermCond = true
      this.trackTapEvent({
        name: `upsellingcom_getpdfcontract${'24'}_tap`,
        params: {
          supply_type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.client.clientOwnerCode || ''
        }
      })
    },
    goToSpeakWith() {
      this.$emit('setFlowType', 'support')
      this.$emit(
        'goToStep',
        this.commodityType === 'ele' ? 15 : 13,
        'upsellingcom_askhelp_open'
      )
    }
  },
  components: {
    PdfViewer,
    CustomDialog,
    ServiceUnavailableDialog,
    MYSButton,
    Placeholder
  }
}
</script>
<style lang="scss" scoped>
.quotation {
  width: 100%;
  height: 100%;
  position: relative;

  &__back {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 500;
      color: black;
      cursor: pointer;
    }

    &::before {
      content: url('../../../../assets/ic-chevron-sx-blue.svg');
      width: 24px;
      margin-right: 16px;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  &__offer {
    width: calc(50% - 15px);
    padding: 24px;
    border-radius: 24px;
    border: solid 1px #ccd0e1;
    margin: 20px auto;

    .mounth {
      line-height: 1.25;
      font-weight: 500;
      text-align: center;
    }

    .box {
      margin: 16px 0;
      padding: 24px;
      border-radius: 16px;
      background-color: #f8f9fc;

      .img {
        width: 48px;
      }

      .bold {
        h5,
        h2 {
          font-weight: 500;
          line-height: 24px;
        }
      }

      .download {
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;

        &::after {
          content: url('../../../../assets/ic_download_small_blue.svg');
          width: 13px;
          margin-left: 10px;
        }
      }

      .type {
        font-size: 24px;
        font-weight: 500;
      }
    }

    .available {
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.8px;
    }

    .include {
      margin-top: 20px;

      h5 {
        color: $srg-blue;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1.2px;
      }
    }
  }

  .callMe {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    color: $srg-blue;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.cta {
  margin-top: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 497px;
}
.howCalculated {
  color: $srg-blue;
  cursor: pointer;
}

.priceHeaderContainer {
  height: 236px;
  width: 604px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.priceHeader {
  height: 183px;
  width: 652px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $neutri-neutral-10;
  padding: 24px;
  border-radius: 16px;
  &__title {
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    &__text {
      height: 43px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__number {
      display: flex;
    }
  }
  &__tip {
    background: $neutri-neutral-30;
    width: 113px;
    height: 28px;
    padding: 7px;
    border-radius: 8px;
  }
  &__offertName {
    display: flex;
    justify-content: space-between;
  }
}
.benefitsContainer {
  height: auto;
  .benefits {
    display: block;
    .benefit {
      height: 24px;
      display: flex;
      align-items: center;
      &__icon {
        height: 24px;
        margin-right: 12px;
      }
      &__info {
        display: flex;
        align-items: center;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}
.divider {
  border-color: rgba(0, 0, 0, 0);
  background-color: $neutri-neutral-20;
}
.contractConditions {
  height: 37px;
  display: flex;
  align-items: center;
  color: $srg-blue;
  img {
    margin-left: 8px;
    cursor: pointer;
  }
}
.skeletonUpselling {
  display: flex;
  flex-direction: column;
}
</style>
