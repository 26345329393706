const ENV_CONFIGS = {
  ENV: process.env.VUE_APP_ENV,
  ENABLE_ADMIN_LOGIN: process.env.VUE_APP_ADMIN || false,
  ENABLE_SUPERLOGIN_ROUTE: process.env.VUE_APP_SUPERLOGIN || false,
  BASIC_TOKEN: process.env.VUE_APP_BASIC_TOKEN,
  CMS_BASIC_TOKEN: process.env.VUE_APP_CMS_BASIC_TOKEN,
  ACN_BASE_URL: process.env.VUE_APP_ACN_BASE_URL,
  ACN_SUB_KEY: process.env.VUE_APP_ACN_SUB_KEY,
  CMS_BASE_URL: process.env.VUE_APP_CMS_BASE_URL,
  CMS_CDN_BASE_URL: process.env.VUE_APP_CMS_CDN_BASE_URL,
  PUBLIC_DOCS_BASE_URL: process.env.VUE_APP_PUBLIC_DOCS_BASE_URL,
  CFP_BASE_URL: process.env.VUE_APP_CFP_BASE_URL,
  CHECKUP_BASE_URL: process.env.VUE_APP_CHECKUP_BASE_URL,
  SORGENIA_SERVICES_BASE_URL: process.env.VUE_APP_SORGENIA_SERVICES_BASE_URL,
  WEKIT_BASE: process.env.VUE_APP_WEKIT_BASE,
  BIDGELY_ENDPOINT: process.env.VUE_APP_BIDGELY_ENDPOINT,
  MYSHOP_ENDPOINT: process.env.VUE_APP_MYSHOP_ENDPOINT,
  MSG_CENTER_DELAY: process.env.VUE_APP_MSG_CENTER_DELAY,
  CHATBOT_URL: process.env.VUE_APP_CHATBOT_URL,
  CHATBOT_URL_SALES: process.env.VUE_APP_CHATBOT_URL_SALES,
  CATEGORY_LIGHTGAS: process.env.VUE_APP_CATEGORY_LIGHTGAS,
  CATEGORY_FIBER: process.env.VUE_APP_CATEGORY_FIBER,
  CATEGORY_PDR: process.env.VUE_APP_CATEGORY_PDR,
  ENERGY: process.env.VUE_APP_ENERGY,
  PHOTOVOLTAIC: process.env.VUE_APP_PHOTOVOLTAIC,
  DYNAMIC_LINKS: {
    sorgeniaSiteDocuments: process.env.VUE_APP_DYNAMIC_LINKS_DOCS,
    termsAndConditions: process.env.VUE_APP_DYNAMIC_LINKS_CONDITIONS, // termini e condizioni
    informativePrivacyWeb: process.env.VUE_APP_DYNAMIC_LINKS_PRIVACY, // informativa privacy
    generalClientsInformative: process.env.VUE_APP_DYNAMIC_LINKS_INFORMATIVE, // trattamento dati personali
    optionalConsents: process.env.VUE_APP_DYNAMIC_LINKS_CONSENT, // consensi opzionali
    informativaSIC: process.env.VUE_APP_DYNAMIC_LINKS_SIC, // informativa SIC
    informativaGreeners: process.env.VUE_APP_DYNAMIC_LINKS_INF_GREENERS, // informativa greeners
    regolamentoGreeners: process.env.VUE_APP_DYNAMIC_LINKS_REG_GREENRES, // regolamento greeners
    concorsoGreeners: process.env.VUE_APP_DYNAMIC_LINKS_CON_GREENRES, // concorso greeners //TODO non usato, rimuovere?
    noteLegali70: process.env.VUE_APP_DYNAMIC_LINKS_LEGALI, // note legali //TODO non usato, rimuovere?
    recessoFornitore: process.env.VUE_APP_DYNAMIC_LINKS_RECESSO, // recesso //TODO non usato, rimuovere?
    regolamentoCodiceAmico: process.env.VUE_APP_DYNAMIC_LINKS_CODICE_AMICO, // regolamento codice amico //TODO non usato, rimuovere?
    regolamentoGreenersBUS: process.env.VUE_APP_DYNAMIC_LINKS_GREENERS_BUS, // regolamento GreenersBUS //TODO non usato, rimuovere?
    regolamentoFibraPremia: process.env.VUE_APP_DYNAMIC_LINKS_FIBRA_PREMIA, // regolamento operazione a premi "La fibra ti premia",
    privacyLinkCheckup: process.env.VUE_APP_DYNAMIC_LINKS_PRIVACY_CHECKUP
  },
  GOOGLE_PLACES_KEY: process.env.VUE_APP_GOOGLE_PLACES_KEY,
  KALEYRA_APP_ID: process.env.VUE_APP_KALEYRA_APP_ID,
  KALEYRA_INFO_PRIVACY: process.env.VUE_APP_KALEYRA_INFO_PRIVACY,
  KALEYRA_ENV: process.env.VUE_APP_KALEYRA_ENV,
  AMPLITUDE_API_KEY: process.env.VUE_APP_AMPLITUDE_API_KEY,
  FIREBASE: {
    APIKEY: process.env.VUE_APP_FIREBASE_APIKEY,
    AUTHDOMAIN: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
    DATABASEURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
    PROJECTID: process.env.VUE_APP_FIREBASE_PROJECTID,
    STORAGEBUCKET: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    MESSAGINGSENDERID: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
    APPID: process.env.VUE_APP_FIREBASE_APPID,
    MEASUREMENTID: process.env.VUE_APP_FIREBASE_MEASUREMENTID
  },
  VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY:
    process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
}

export { ENV_CONFIGS }
