import Vue from 'vue'

import SocialSharing from 'vue-social-sharing'
import App from './App.vue'
import Components from './components'
import TcVarService from './js/service/tcVarService'
import { capitalize, replaceDecimalPoint } from './js/utils'
import vuetify from './plugins/vuetify'
import router from './router/router'
import store from './store'

import breadcrums from './mixins/breadcrumbs'

// Import tracking initialization scripts
import { ENV_CONFIGS } from '@/js/configs.js'
import VueRx from 'vue-rx'

// import VueGooglePlaces from 'vue-google-places'

import eventBus from './eventBus'
import { initKaleyra } from './js/service/bandyer'
import { initializeRemoteConfig } from './js/service/firebaseService'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import { VueReCaptcha } from 'vue-recaptcha-v3'
if (ENV_CONFIGS.ENV !== 'pro') {
  import('@/js/dynaJsSnippet')
}

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: ENV_CONFIGS.GOOGLE_PLACES_KEY
})
// install vue-rx
Vue.use(VueRx)
Vue.use(SocialSharing)
Vue.mixin(breadcrums)
// Vue.use(VueGooglePlaces)
Vue.use(VuePlyr, {
  plyr: {}
})

Components.install(Vue)

/*
 *  Main eventBus used throughout the whole app to emit/listen to events used to implement communication among different components
 */
const tcVarService = new TcVarService()

// Vue.use(VueReCaptcha, {
//   siteKey: ENV_CONFIGS.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
// })

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

initKaleyra()
initializeRemoteConfig()
Vue.prototype.$filters = {
  capitalize,
  formatDateWithSeparator: (value, separator = '.') => {
    function pad(s) {
      return s < 10 ? '0' + s : s
    }

    if (!value) return ''

    // In case of gas, take only the date-part of the string, to ignore the timezone that would move one day froward the final data (ie, managing a wrong date from ACN)
    // In this case we receive a timestamp with timezone, which therfore included the "T" char. Using this to determine wthether rework the date or not (since we use this filter for other input formats too)
    if (value.indexOf('T') > -1) value = value.substring(0, value.indexOf('T'))

    var d

    value = value.split('/').reverse().join('-') // light format must be reworked
    d = new Date(value)

    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
      separator
    )
  },
  formatNumberGreen(val) {
    return val ? val.match(/.{1,3}/g).join('.') : ''
  },
  formatCreditCardTail(tail) {
    if (tail.indexOf('*') > -1) {
      return tail
    }

    return '**** **** **** ' + tail
  },
  descFile: function (val) {
    return val.groupFile === 'Nuove condizioni di contratto'
      ? 'Nuove condizioni di contratto'
      : val.descFile
  },
  formatSpeed(speedVal) {
    if (parseInt(speedVal) < 1000) {
      return `${speedVal} Mega`.replace('.', ',')
    } else {
      return `${parseInt(speedVal) / 1000} Giga`.replace('.', ',')
    }
  },
  formatDateSplitJoin(val) {
    if (val) {
      return val.split('/').join('.')
    }
    return ''
  },
  formatDateIntl(val) {
    if (!val) return ''
    return new Intl.DateTimeFormat('it', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(new Date(val))
  },
  formatDateCustom(value) {
    let date = new Date(value)
    return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.${
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }.${date.getFullYear()}`
  },
  clientName(client) {
    if (client.firstName && client.lastName) {
      return client.firstName + ' ' + client.lastName
    } else return client.businessName
  },
  replaceDecimalPoint
}

export { eventBus, tcVarService }
